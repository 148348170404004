import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../user';
import { UserService } from '../user.service'; 
import { OrganizationService } from '../../organizations/organization.service';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';

import 'rxjs/add/operator/toArray';
import { APPCONSTANTS } from  '../../app-constants';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, AfterViewInit {

    // userDatabase = new UserDatabase(this.userService);
    // dataSource;
    appConstants: any = "";
    organizations: any ="";
    roles: any = "";
    userOrg: any = "" ;
    isRateLimitReached;
    isLoadingResults = true;
    displayedColumns = [
        'c_firstname',
        'c_email',
        'actions'];
    userObservable;
    permissions: any = "";
    selectedOrg;
    dataSource = new MatTableDataSource<User>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router,
        private userService: UserService,
        public snackBar: MatSnackBar,
        private organizationService: OrganizationService
        ) {
        console.log("constructor reached");
        
    }



    ngOnInit() {
        this.roles = JSON.parse(localStorage.getItem('roles'));
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.appConstants = APPCONSTANTS;
        this.userOrg = JSON.parse(localStorage.getItem('org'));
        this.selectedOrg = this.userOrg['id'];
        console.log(this.permissions.indexOf(this.appConstants.getAllRootOrgs))
        if(this.permissions.indexOf(this.appConstants.getAllRootOrgs) !== -1){
            this.getAllRootOrgs();
        }else{
            this.getChildOrganizations(this.userOrg['id']);
        }
        console.log();
        this.getUsers(this.userOrg["id"]);
    }

    organizationChange(event){
        this.selectedOrg = event.value;
        console.log(event.value);
        this.getUsers(event.value);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log(this.dataSource);
        console.log(this.paginator);
    }
    getChildOrganizations(orgid) {
        this.organizationService.getChildOrganizations(orgid)
          .subscribe(orgs => {
            console.log(orgs);
            this.organizations = orgs;
            this.organizations.push(this.userOrg);
          });
      }
      getAllRootOrgs() {
        this.organizationService.getAllRootOrganizations()
          .subscribe(orgs => {
            console.log(orgs);
            this.organizations = orgs;
          });
      }
    getUsers(orgid) {
        console.log(orgid);
        this.userObservable = this.userService.getUsersByOrg(orgid);
        this.userObservable.toArray()
            .subscribe(arr => {
                console.log(arr);
                this.dataSource.data = arr[0];
                this.isLoadingResults = false;
            });
    }

    getCreateUserForm() {
        this.router.navigate(['users/create']);
    }
    confirmDelete(id: string) {
        if (confirm(' Deleting ' + id + '. This action is irreversible. Are you sure? ')) {
            console.log('confirmed by user');
            this.isLoadingResults = true;
            this.userService.deleteUser(id)
                .subscribe(() => {
                    this.getUsers(this.selectedOrg);
                },
                err => {
                    this.getUsers(this.selectedOrg);
                    this.openSnackBar(err);
                });
        } else {
            console.log('cancelled by user');
            this.openSnackBar('Cancelled by user!');
        }

    }

    openSnackBar(message: string) {
      this.snackBar.open(message, "Dismiss", {
        duration: 5000
      });
    }


}
