import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../auth.service';
import { BURL } from '../back-end-urls';
import { CustomRole } from './custom-role';


@Injectable({
  providedIn: 'root'
})
export class CustomRoleService {

  constructor(
      private http: Http,
      private authService: AuthService
    ) { }

  /**
   * create a custom role
   * @param role string
   * @param description string
   */
  createRole(role: string, description: string): Observable<any> {
    const data = {
      role,
      description
    };
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallets`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  /**
   * create a custom permission
   * @param permission string
   * @param description string
   */
  createPermission(permission: string, description: string): Observable<any> {
    const data = {
      permission,
      description
    };
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallets`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  createCustomRole(customRole: CustomRole): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-ops-user/create-custom-role`, customRole, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
