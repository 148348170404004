import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../organization.service';
import { Organization } from '../organization';
import { Location } from '@angular/common';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-organization-update',
  templateUrl: './organization-update.component.html',
  styleUrls: ['./organization-update.component.css']
})
export class OrganizationUpdateComponent implements OnInit {

  organizationForm: FormGroup;

  // for form labels
  hro = this.organizationService.human_readable_obj;

  isLoadingResults = true;
  id;
  userOrg;
  organizations = [];
  croppedImage : any = '';
  imageChangedEvent: any = '';
  selectedFile: Blob;
  image;
  organization;
  
  c_org_type_enum_values = [
    {value: 'MASTER'},
    {value: 'PARTNER'},
    {value: 'VENDOR'},
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private _location: Location,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.getChildOrganizations(this.userOrg['id']);
    this.organizationService.getOrganization(this.id)
      .subscribe(organization => {
        this.organization = organization;
        this.organizationForm = this.fb.group({
          c_org_name : [organization.c_org_name, Validators.required] ,
          c_org_domain : [organization.c_org_domain] ,
          c_org_type : [organization.c_org_type, Validators.required] ,
          c_parent_org_id : [organization.c_parent_org_id] ,
          c_address : [organization.c_address],
          c_city : [organization.c_city],
          c_state : [organization.c_state],
          c_pin : [organization.c_pin],
          c_country : [organization.c_country],
          c_latlong : [organization.c_latlong],
          orgimg :[null],
          c_org_img:[organization.c_org_img],

        });
        this.isLoadingResults = false;
      });
  }
  filechange(event) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    var file:File =event.target.files[0];
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.croppedImage = myReader.result;
      console.log(this.croppedImage)
      this.organization.c_org_img = this.croppedImage.split(",")[1];
    }
    myReader.readAsDataURL(file);
    
   
  }
  
  updateOrganization() {
    console.log(this.organizationForm.value);
    if(this.croppedImage != ""){
      this.organizationForm.patchValue({ c_org_img : this.croppedImage.split(",")[1] });
    }
    this.organizationService.updateOrganization(this.organizationForm.value, this.id)
      .subscribe(
      entity => {
        console.log('organization was updated');
        this.router.navigate(['organizations', `${this.route.snapshot.params['id']}`]);
      },
      err => {
        // this.errorMessage = err;
        console.error(err);
      }
      );

  }

  getChildOrganizations(orgid) {

    this.organizationService.getChildOrganizations(orgid)
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
        this.organizations.push(this.userOrg);
      });
  }

  goBack() {
    this._location.back();
  }

}
