import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../wallet.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EventService } from '../../event.service';
import { Organization } from 'src/app/organizations/organization';
import { MatSnackBar } from '@angular/material';
import { OrganizationService } from 'src/app/organizations/organization.service';
import { APPCONSTANTS } from '../../../app-constants';
import { AssignUsersService} from '../../../locs/loc-view/assign-users/assign-users.service'
import {UserService} from '../../../users/user.service';
@Component({
  selector: 'app-assign-event-users',
  templateUrl: './assign-event-users.component.html',
  styleUrls: ['./assign-event-users.component.css']
})
export class AssignEventUsersComponent implements OnInit {
  assocVendorEventForm: FormGroup;
  appConstants;
  permissions;
  orgVendors;
  process: boolean;
  id: string;
  selected = [];
  allUsers = [];
  selectedUsers = [];
  eventObj;
  userOrg;
  constructor(
    private walletService: WalletService,
    private organizationService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private userService: UserService,
    private assignUserService: AssignUsersService
  ) { 
    this.eventObj = JSON.parse(localStorage.getItem('event_obj'));
    this.userOrg = JSON.parse(localStorage.getItem('org'));
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.getAssociatedUsersofEvent();
    
    this.walletService.vendorAddedToEvent$
    .subscribe(() => {
      this.process = true;
      //this.getEventPartnerssandUsers({c_event_id: this.id})
      this.getAssociatedUsersofEvent()
    });
   
  }
  getAssociatedUsersofEvent(){
    this.assignUserService.getAssignedUsersofEvent(this.id)
    .subscribe(data => {
      this.selectedUsers = [];
      if(data.status == undefined){
        data.forEach(element => {
          if(this.selectedUsers.indexOf(element.id) !== -1){
              console.log("already present")
          }else{
            this.selectedUsers.push(element.id); 
          }
          
        });
      }else{

      }
      
   })
    console.log(this.selectedUsers);
    this.getEventPartnerssandUsers({c_event_id: this.id });
  }
  getEventPartnerssandUsers(eventID) {
    this.allUsers = [];
    this.walletService.getEventVendor({c_event_id: eventID })
      .subscribe(orgs => {
        console.log(orgs);
        orgs.forEach(indorg => {
          //console.log(indorg)
          this.userService.getUsersByOrg(indorg['id']).subscribe(data => {
           // console.log(data);  
            data.forEach(inddata => {
              inddata.orgname = indorg["c_org_name"];
              this.allUsers.push(inddata);
            });
            
            console.log(this.allUsers)
            
            })
          
        });
        
       
      });
      
    
      
  }
 
  changeUser(event)
  {
    if(event.isUserInput) {
      console.log(event.source.value, event.source.selected);
      this.assignUserService.allocateordeallocateUsertoEvent(this.id, event.source.value, event.source.selected)
      .subscribe(data =>
        {
          console.log(data);
          this.openSnackBar('Success!');
          
          
        },
        err => {
          console.log(err);
          this.openSnackBar(err);
        }
        );
    }
  }
  
  
 
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

}
