import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EventService } from '../event.service';
import { Event } from '../event';
import { Location } from '@angular/common';
import { LocService } from '../../locs/loc.service';
import {Loc} from '../../locs/loc';
import { MatSnackBar } from '@angular/material';
import { OrganizationService } from '../../organizations/organization.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.css'],

})
export class EventCreateComponent implements OnInit {

  eventForm: FormGroup;
  organizations;
  // for form labels
  hro = this.eventService.human_readable_obj;
  locs: Loc[];
  userOrg ;
  c_type_enum_values = [
    {value: 'CONFERENCE'},
    {value: 'CONCERT'},
    {value: 'WEDDING'},
    {value: 'PARTY'},
    {value: 'CORPORATE'},
    {value: 'OTHER'},
    {value: 'FOREVER'},
  ];
  c_status_enum_values = [
    {value: 'ACTIVE'},
    {value: 'INACTIVE'},
    {value: 'ENABLED'},
    {value: 'DISABLED'},
    {value: 'LOCKED'}
  ];
  roles;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private _location: Location,
    private fb: FormBuilder,
    private locService: LocService,
    public snackBar: MatSnackBar,
    private organizationService: OrganizationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.getChildOrganizations(this.userOrg['id']);
    //this.getLocs();
    this.eventForm = this.fb.group({
      c_name: [null, Validators.required],
      c_type: [null, Validators.required],
      c_status: [null],
      c_date_from: [null, Validators.required],
      c_date_to: [null, Validators.required],
      c_location_id: [null, Validators.required],
      c_org_id:[null, Validators.required]

    });
  }

  getChildOrganizations(orgid) {

    this.organizationService.getChildOrganizations(orgid)
    
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
        this.organizations.push(this.userOrg);
      });
  }
  organizationChange(event){
    //console.log(event);
    this.getLocs(event.value);
  }
  createEvent() {
    //let startDate = new Date(this.eventForm.value.c_date_from).toLocaleString().slice(0, 19).replace('T', ' ').replace(',', '');
    //let endDate = new Date(this.eventForm.value.c_date_to).toLocaleString().slice(0, 19).replace('T', ' ').replace(',', '' );
    this.eventForm.patchValue({c_date_from: this.datePipe.transform(this.eventForm.value.c_date_from, "yyyy-MM-dd HH:mm") });
    this.eventForm.patchValue({c_date_to: this.datePipe.transform(this.eventForm.value.c_date_to, "yyyy-MM-dd HH:mm") });
    
    console.log( JSON.stringify(this.eventForm.value.c_date_from));
    this.eventService.createEvent(this.eventForm.value)
      .subscribe(event => {
        // this.successMessage = 'Event was created!';
        console.log('event was created');

        // navigate back to the index page
        this.router.navigate(['/events']);
      },
      err => {
        // this.errorMessage = err;
        console.log(err);
        this.openSnackBar(err);
      }
      );

  }

  getLocs(orgid) {
    this.locService.getLocs(orgid)
      .subscribe(locs => {
        this.locs = locs;
        console.log(locs)
      });
  }

  goBack() {
    this._location.back();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

}
