import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import {CustomRoleService} from './custom-role.service';
import { Router } from '@angular/router';
import {AuthService} from '../auth.service';
import { MatSnackBar } from "@angular/material";

@Component({
  selector: 'app-custom-roles',
  templateUrl: './custom-roles.component.html',
  styleUrls: ['./custom-roles.component.css']
})
export class CustomRolesComponent implements OnInit {
  permissions: string[];
  customRoleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private customRoleService: CustomRoleService,
    private authService: AuthService,
    private router: Router,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getSysPermissions();
    this.customRoleForm = this.fb.group({
      role_name: [null, Validators.required],
      role_desc: [null, Validators.required],
      permissions: [null, Validators.required]
    });
  }

  createCustomRole() {
    debugger;
    console.log(this.customRoleForm.value.role_name)
    console.log(this.customRoleForm.value.permissions)
    if(this.customRoleForm.value.permissions.indexOf(this.customRoleForm.value.role_name) > -1){
        //found
        this.openSnackBar("One Of the Permission is having same name as Role, Please Change the role name");
    }else{
   // console.log(permissionArr)
    this.customRoleService.createCustomRole(this.customRoleForm.value)
    .subscribe(
      customRole => {
        console.log("Custom Role Created");
        this.openSnackBar("Custom Role Created");
        this.customRoleForm.reset();
        this.router.navigate(['/custom-roles']);
      },
      err => {
        console.log("There was an error");
        this.openSnackBar("There was an error");
      }
    );
    }
  }

  // addPermissionButtonClick(): void {
  //   (<FormArray>this.customRoleForm.get('permissions')).push(this.addPermissionFormGroup());
  // }
  // removePermissionButtonClick(index): void {
  //   (<FormArray>this.customRoleForm.get('permissions')).removeAt(index);
  // }

  getSysPermissions() {
    this.authService.getAllSystemPermissions()
      .subscribe(permissions => {
        this.permissions = permissions;
        // this.showSpinner = false;
        console.log(this.permissions);
      });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
