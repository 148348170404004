import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { LocService } from '../loc.service';
import { Loc } from '../loc';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { APPCONSTANTS } from '../../app-constants';
@Component({
  selector: 'app-loc-view',
  templateUrl: './loc-view.component.html',
  styleUrls: ['./loc-view.component.css']
})
export class LocViewComponent implements OnInit {
  appConstants;
  permissions;
  showSpinner = true;
  displayedColumns = ['name', 'details'];
  ELEMENT_DATA = [];
  image = null;
  dataSource = new MatTableDataSource<Element>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locService: LocService,
    private _location: Location) { }

  ngOnInit() {
    // grab the id from the url
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    let id = this.route.snapshot.params['id'];
    console.log(id);
    this.locService.getLoc(id)
      .subscribe(loc => {
        console.log(loc);

        for (let i in loc) {
          console.log(i);
          let key = this.locService.human_readable_obj[i];
          if(i == "c_img"){
            if(loc[i] != null){
              this.image = "data:image/jpeg;base64"+","+loc[i];
              console.log(this.image)
            }
            
          }else{
            this.ELEMENT_DATA.push({ name: key, details: loc[i] });
          }
        }

        console.log("Elements : " + JSON.stringify(this.ELEMENT_DATA));
        this.dataSource.data = this.ELEMENT_DATA;
        this.showSpinner = false;
      });
  }

  goBack() {
    this._location.back();
  }



}
