import { Component, OnInit } from '@angular/core';
import { AccessPointService } from '../locs/loc-view/access-point.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.css']
})
export class CanvasComponent implements OnInit {
  locid: string;
  accessPoints;
  accessToken: string;
  showSpinner = true;
  constructor(private accessPointService: AccessPointService,
    private _location: Location  ) { }

  ngOnInit() {
    this.locid = localStorage.getItem("canvaslocid");
    this.accessToken = localStorage.getItem("access_token");
    this.loadScripts();
    this.fetchAccessPointsofLoc();
  }
  private fetchAccessPointsofLoc(){
   this.accessPointService.getAccessPoints(this.locid, "AP")
    .subscribe(data => {
      this.showSpinner = false;
      console.log(data);
      this.accessPoints = data;
      // this.dataSource.data = data;
    });
  }
  goBack() {
    this._location.back();
  }
  private loadScripts() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script");
    console.log(scripts.length);
    for (var i = 0; i < scripts.length; ++i) {
      console.log(scripts[i]);
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("canvas")) {
        isFound = true;
        document.getElementsByTagName('head')[0].appendChild(scripts[i]);
      }
    }
    console.log("isFound :" + isFound);
    
      var dynamicScripts = ["../../customjavascript/canvas.js"];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

  }

}
