import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../auth.service';
import { BURL } from '../back-end-urls';
import { Wallet } from './wallet';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  // Observable Source
  private vendorAddedToEventSource = new Subject();

  // Observable Stream
  vendorAddedToEvent$ = this.vendorAddedToEventSource.asObservable();



  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

  /**
   * create a wallet for an event
   * @param wallet Wallet
   */
  createWallet(wallet: Wallet): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallets`, wallet, { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }

  /**
   * get wallet id for an event
   * @param id number
   */
  getWalletId(c_event_id): Observable<any> {
    const data = {
      c_event_id
    };
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallet/get-wallet-id`, data, { headers })
      .map(res => res.json())
      .do( res => {
        if (res.status === false ) {
          throw new Error(res.message);
        }
      }
      )
      .catch(this.handleError)
        .mergeMap((res) => {
          let response: any = res;
          // console.log(data);
          // console.log(data.status);
          if (response.status) {

            return this.http.get(`${BURL.mainURLYii}/t-wallets/${response.data}`, { headers });
          }
        })
        .map(res => res.json())
        .catch(this.handleError);
  }
    getWalletBalanceByEmail(data): Observable<any>{
      console.log("input event parte"+ JSON.stringify(data))  
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const postdata = {
      "c_event_id": data['c_event_id'],
      "c_wallet_id": data['c_wallet_id'],
      "c_b2c_email": data['c_b2c_email']
    }
    return this.http.post(`${BURL.mainURLYii}/t-wallet/get-wallet-balance-by-email`, postdata, { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
    } 
  getEventVendor(data): Observable<any> {
    console.log("input event parte"+ JSON.stringify(data))  
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-event/get-event-partners`, data['c_event_id'], { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }
  chargeWallet(data): Observable<any>{
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallet/charge-wallet`, data, { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }
  dischargeWallet(data): Observable<any>{
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallet/wallet-transaction`, data, { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }
  getWalletVendor(data): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallet/get-wallet-partners`, data, { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }

  associateorunassociateVendorWithEvent(eventid:number, vendorid: string, associate:boolean): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const data = {
      "c_event_id" : eventid,
      "vendors" : [ vendorid]
    }
    let url = ""
    if(associate == true){
      url = `${BURL.mainURLYii}/t-event/assign-partners-with-event`;
    }else{
      url = `${BURL.mainURLYii}/t-event/un-assign-partners-from-event`;
    }
    return this.http.post(url, data, { headers })
      .map(res => res.json())
      .do(() => this.vendorAddedToEvent())
      .catch(this.handleError);
  }
 

  associateorunassociateVendorWithWallet(walletid:number, vendorid: string, associate:boolean): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const data = {
      "c_wallet_id" : walletid,
      "vendors" : [ vendorid]
    }
    let url = ""
    if(associate == true){
      url = `${BURL.mainURLYii}/t-wallet/assign-partners-with-wallet`;
    }else{
      url = `${BURL.mainURLYii}/t-wallet/un-assign-partners-from-wallet`;
    }
    return this.http.post(url, data, { headers })
      .map(res => res.json())
      .do(() => this.vendorAddedToEvent())
      .catch(this.handleError);
  }

  vendorAddedToEvent() {
    this.vendorAddedToEventSource.next();
    //refresh userlist depends on partners
  }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
