import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { User } from '../users/user';
import { Organization } from '../organizations/organization';
import { APPCONSTANTS } from '../app-constants';
import { UserService } from '../users/user.service';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private userService: UserService) { }
  sidebarActive = false;
  showSpinner = true;
  usersname;
  usersrole;
  orgName;
  permissions;
  appConstants;
  usermail;
  user;
  userUpdated;
  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.authService.getOpsUserDetails().subscribe();
    this.authService.userDetailsLoaded$.subscribe(() => {
      let user: User = JSON.parse(localStorage.getItem('user'));
      this.user = JSON.parse(localStorage.getItem('user'));
      let org: Organization = JSON.parse(localStorage.getItem('org'));
      this.orgName = org.c_org_name;
      this.showSpinner = false;
      this.usersname = user.c_firstname + " " + user.c_lastname ;
      this.usermail = user.c_email;
      this.userService.getUpdatedUser().subscribe();
      this.userService.getUpdatedUser().subscribe(updatedUser =>{
          debugger;
          this.userUpdated = updatedUser;
          console.log(this.userUpdated)
          if(this.user.c_email == this.userUpdated.c_email){
            this.usersname = this.userUpdated.c_firstname + " " + this.userUpdated.c_lastname ;
          }
      })

    });

    // this.usersname = localStorage.getItem('usersname');
    // this.usersrole = localStorage.getItem('usersrole').replace(/_|\-/, ' ').toLowerCase();

  }

/**
 * Log a user out.
 */
  logout() {
    this.authService.logout();
    this.router.navigate(['signin']);
  }


  toggleSidebar() {
    this.sidebarActive = !this.sidebarActive;
    // console.log(this.sidebarActive);
  }

}
