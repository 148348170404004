import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locs',
  templateUrl: './locs.component.html',
  styleUrls: ['./locs.component.css']
})
export class LocsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
