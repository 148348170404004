const APPCONSTANTS = {
    //user roles
    SUPER_USER :'SUPER_USER',
    SUPER_USER_ADMIN:'SUPER_USER_ADMIN',
    ORG_ADMIN: 'ORG_ADMIN',
    getAssignedEventsOfOpsUser: 'getAssignedEventsOfOpsUser',
    updateB2CUser: 'updateB2CUser',
    //SUPER USER permissions
    getAllRootOrgs: 'getAllRootOrgs',
    getOrgOpsUsers: 'getOrgOpsUsers',
    getChildOrganizations : 'getChildOrganizations',
    getOrgLocations: 'getOrgLocations',
    getOrgEvents: 'getOrgEvents',
    createCustomRole: 'createCustomRole',
    createOpsUser: 'createOpsUser',
    updateOpsUser: 'updateOpsUser',
    deleteOpsUser: 'deleteOpsUser',
    viewOpsUser: 'viewOpsUser',
    grantRoleToUser: 'grantRoleToUser',
    allocateLocationToOpsUser:'allocateLocationsToOpsUser',
    createOrganization: 'createOrganization',
    createSubOrganization:'createSubOrganization',
    viewOrganization: 'viewOrganization',
    updateOrganization: 'updateOrganization',
    deleteOrganization: 'deleteOrganization',
    createChildLocation: 'createChildLocation',
    viewChildLocation: 'viewChildLocation',
    updateLocation: 'updateLocation',
    deleteLocation: 'deleteLocation',
    createLocationCanvas: 'createLocationCanvas',
    viewAccessPoint: 'viewAccessPoint',
    viewAccessPointGroup:'viewAccessPointGroup',
    createAccessPoint: 'createAccessPoint',
    createAccessPointGroup :'createAccessPointGroup',
    deleteAccessPoint: 'deleteAccessPoint',
    deleteAccessPointGroup: 'deleteAccessPointGroup',
    allocateAccessPoints: 'allocateAccessPoints',
    createEvent : 'createEvent',
    viewEvent: 'viewEvent',
    viewWallet: 'viewWallet',
    createWallet: 'createWallet',
    viewVendor: 'viewVendor',
    updateEvent: 'updateEvent',
    deleteEvent: 'deleteEvent',
    getEventPartners :'getEventPartners',
    getWalletVendor : 'getWalletVendor',
    assignPartnersWithEvent : 'assignPartnersWithEvent',
    associateVendorWithWallet : 'associateVendorWithWallet',
    getAssignedB2cUsersOfAssignedEvent: 'getAssignedB2cUsersOfAssignedEvent',
    getAssignedB2cUsersOfEvent: 'getAssignedB2cUsersOfEvent',
    getWalletId: 'getWalletId',
    chargeWallet: 'chargeWallet',
    walletTransaction: 'walletTransaction'
  };
  
export {
      APPCONSTANTS
};
  