import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../../wallet.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { APPCONSTANTS } from '../../../../../app/app-constants';
import { AccessPointService } from "../../../../locs/loc-view/access-point.service";
// import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-charge-wallet',
  templateUrl: './charge-wallet.component.html',
  styleUrls: ['./charge-wallet.component.css']
})
export class ChargeWalletComponent implements OnInit {
  c_txn_details = [
    {value: 'CASH'},
    {value: 'CARD'},
    {value: 'GIFT'},
    {value: 'COUPON'},
  ];
  constructor( private fb: FormBuilder,
     public snackBar: MatSnackBar, 
     private accessPointService: AccessPointService,
     private walletService: WalletService) { }
  chargeForm: FormGroup;
  accessPoints;
  event;
  wallet;
  delegateWallet;
  walletBalance;
  selectedAp;
  ngOnInit() {
    //fetch location of event
    //get access points
    //show access point of tx    
    this.event = JSON.parse(localStorage.getItem("adddelegateevent"));
    this.delegateWallet = JSON.parse(localStorage.getItem("walletdelid"));
    this.getAccessPoints();
    this.wallet = JSON.parse(localStorage.getItem("wallet"));
    this.walletBalance = JSON.parse(localStorage.getItem("walletBalance"));
    console.log(this.event);
    console.log(this.delegateWallet);
    console.log(this.wallet);
    this.chargeForm = this.fb.group({
      'c_wallet_id': [this.wallet['id']],
      'c_asset_id': [this.walletBalance['c_asset_id']],
      'c_ap_id': [null, Validators.required],
      'c_name': [null, Validators.required],
      'c_ap_name': [null, Validators.required],
      'c_txn_detail': [null, Validators.required],
      'c_txn_detail_value': [null, Validators.required],
      'c_txn_value': [null, Validators.required]
     });
    
  }
  apPointChanged(event, ap){
    ap = JSON.parse(ap)
    console.log(ap.id)
    console.log(ap.c_name)
    this.chargeForm.patchValue({"c_ap_id": ap.id})
    this.chargeForm.patchValue({"c_ap_name": ap.c_name})
  }
  chargeWallet(){
    console.log(this.chargeForm.value)
    this.walletService.chargeWallet(this.chargeForm.value).subscribe(data => {
      console.log(data);
      if(data.status == true){
        this.snackBar.open(data.message)
        location.reload();
      }else{
        this.snackBar.open(data.message)
      }

    });

  }
  getAccessPoints() {
    //locid of event
    const id = this.event["c_location_id"]
    this.accessPointService.getAccessPoints(id, "TX")
    .subscribe(data => {
      console.log(data);
      this.accessPoints = data;
      // this.dataSource.data = data;
    });
  }
  
}
