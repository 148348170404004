import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContentComponent } from "./content/content.component";
import { SigninComponent } from "./signin/signin.component";
import { AboutComponent } from "./about/about.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { WelcomeHomeComponent } from "./content/welcome-home/welcome-home.component";
import { UsersComponent } from "./users/users.component";
import { UserListComponent } from "./users/user-list/user-list.component";
import { UserCreateComponent } from "./users/user-create/user-create.component";
import { UserUpdateComponent } from "./users/user-update/user-update.component";
import { UserViewComponent } from "./users/user-view/user-view.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { NotAllowedComponent } from "./not-allowed/not-allowed.component";
import { OrganizationsComponent } from "./organizations/organizations.component";
import { OrganizationListComponent } from "./organizations/organization-list/organization-list.component";
import { OrganizationCreateComponent } from "./organizations/organization-create/organization-create.component";
import { OrganizationUpdateComponent } from "./organizations/organization-update/organization-update.component";
import { OrganizationViewComponent } from "./organizations/organization-view/organization-view.component";
import { LocsComponent } from "./locs/locs.component";
import { LocListComponent } from "./locs/loc-list/loc-list.component";
import { LocCreateComponent } from "./locs/loc-create/loc-create.component";
import { LocUpdateComponent } from "./locs/loc-update/loc-update.component";
import { LocViewComponent } from "./locs/loc-view/loc-view.component";
import { EventsComponent } from "./events/events.component";
import { EventListComponent } from "./events/event-list/event-list.component";
import { EventCreateComponent } from "./events/event-create/event-create.component";
import { EventUpdateComponent } from "./events/event-update/event-update.component";
import { EventViewComponent } from "./events/event-view/event-view.component";
import { CustomRolesComponent } from "./custom-roles/custom-roles.component";
import { AuthGuardService } from "./auth-guard.service";
import { RoleGuardService } from "./role-guard.service";
import { CanvasComponent } from './canvas/canvas.component';
import { B2cUserComponent } from './b2c-user/b2c-user.component'
import { AddDelegatesToEventComponent } from './events/add-delegates-to-event/add-delegates-to-event.component';
import { UpdateB2cUserComponent } from './b2c-user/update-b2c-user/update-b2c-user.component';
import { DelegateWalletComponent } from './events/add-delegates-to-event/delegate-wallet/delegate-wallet.component';
const routes: Routes = [
  { path: "signin", component: SigninComponent },
  
  {
    path: "",
    component: ContentComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],

    children: [
      // path: '', component: ContentComponent,  children: [
      { path: "", component: WelcomeHomeComponent },
      { path: "b2cuser", component: B2cUserComponent  },
      { path: "update-b2c-user", component: UpdateB2cUserComponent},
      { path: "delegate-wallet", component: DelegateWalletComponent},
      { path: "change-password", component: ChangePasswordComponent },
      { path: "about", component: AboutComponent },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        data: {
          expectedRole: "getOrgOpsUsers"
        },
        children: [
          {
            path: "",
            component: UserListComponent,
            data: {
              expectedRole: "getOrgOpsUsers"
            }
          },
          {
            path: "create",
            component: UserCreateComponent,
            data: {
              expectedRole: "createOpsUser"
            }
          },
          {
            path: "update/:id",
            component: UserUpdateComponent,
            data: {
              expectedRole: "updateOpsUser"
            }
          },
          {
            path: ":id",
            component: UserViewComponent,
            data: {
              expectedRole: "viewOpsUser"
            }
          }
        ]
      },
      {
        path: "organizations",
        component: OrganizationsComponent,
        data: {
          expectedRole: "manageOrganization"
        },
        children: [
          {
            path: "",
            component: OrganizationListComponent,
            data: {
              expectedRole: "manageOrganization"
            }
          },
          {
            path: "create",
            component: OrganizationCreateComponent,
            data: {
              expectedRole: "createOrganization"
            }
          },
          {
            path: "update/:id",
            component: OrganizationUpdateComponent,
            data: {
              expectedRole: "updateOrganization"
            }
          },
          {
            path: ":id",
            component: OrganizationViewComponent,
            data: {
              expectedRole: "viewOrganization"
            }
          }
        ]
      },
      {
        path: "locs",
        component: LocsComponent,
        data: {
          expectedRole: "manageLocation"
        },
        children: [
          {
            path: "",
            component: LocListComponent,
            data: {
              expectedRole: "manageLocation"
            }
          },
          {
            path: "create",
            component: LocCreateComponent,
            data: {
              expectedRole: "createLocation"
            }
          },
          {
            path: "update/:id",
            component: LocUpdateComponent,
            data: {
              expectedRole: "updateLocation"
            }
          },
          {
            path: ":id",
            component: LocViewComponent,
            data: {
              expectedRole: "viewLocation"
            }
          }
        ]
      },
      {
        path: "events",
        component: EventsComponent,
        data: {
          expectedRole: "manageEvent"
        },
        children: [
          {
            path: "",
            component: EventListComponent,
            data: {
              expectedRole: "manageEvent"
            }
          },
          {
            path: "create",
            component: EventCreateComponent,
            data: {
              expectedRole: "createEvent"
            }
          },
          {
            path: "update/:id",
            component: EventUpdateComponent,
            data: {
              expectedRole: "updateEvent"
            }
          },
          {
            path: ":id",
            component: EventViewComponent,
            data: {
              expectedRole: "viewEvent"
            }
          }
        ]
      },
      {
        path: "custom-roles",
        component: CustomRolesComponent,
        data: {
          expectedRole: "getAllSystemRoles"
        }
        // children: [
        //   { path: '', component: EventListComponent },
        //   { path: 'create', component: EventCreateComponent },
        //   { path: 'update/:id', component: EventUpdateComponent },
        //   { path: ':id', component: EventViewComponent }
        // ]
      },
      {path: "canvas",
       component: CanvasComponent,
       data: {
        expectedRole: "viewLocation"
      }
    },
    {
      path: "add-delegate",
      component: AddDelegatesToEventComponent,
      data: {
        expectedRole: "getAssignedB2cUsersOfEvent"
      }
    },
    ]
  },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "unauthorized", component: NotAllowedComponent },
  // this has to be last here
  { path: "**", component: NotFoundComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule],
  
})
export class AppRoutingModule {}
