import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { User } from '../../users/user';
import { Organization } from '../../organizations/organization';
import { APPCONSTANTS } from '../../app-constants';
import { UserService } from '../../users/user.service';
@Component({
  selector: 'app-welcome-home',
  templateUrl: './welcome-home.component.html',
  styleUrls: ['./welcome-home.component.css']
})
export class WelcomeHomeComponent implements OnInit {
  constructor(
    private snackBar: MatSnackBar,private authService: AuthService,private router: Router,
    private userService: UserService
  ) { }
  orgName;
  permissions;
  appConstants;
  showSpinner;
  usersname;
  usermail;
  user
  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.authService.getOpsUserDetails().subscribe();
    this.authService.userDetailsLoaded$.subscribe(() => {
      this.user = JSON.parse(localStorage.getItem('user'));
      let org: Organization = JSON.parse(localStorage.getItem('org'));
      this.orgName = org.c_org_name;
      this.showSpinner = false;
      this.usersname = this.user.c_firstname + " " + this.user.c_lastname ;
      this.usermail = this.user.c_email;
      this.userService.userUpdated$.subscribe(updatedUser => {
        console.log("here..............."+updatedUser)
        if(this.user.c_email == updatedUser.c_email){
          console.log("matched..............")
          this.usersname = this.user.c_firstname + " " + this.user.c_lastname ;
        }
      })
    });
    
  }

  routetoB2cUser(){
    this.router.navigate(['/b2cuser'])
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
  routetoUsers(){
      this.router.navigate(['/users']);
  }
  routetoVendors(){
      this.router.navigate(['/organizations'])
  }
  routetoLocations(){
    this.router.navigate(['/locs']);
  }
  routetoEvents(){
    this.router.navigate(['/events']);
  }

}
