import { Component, OnInit, ViewChild, AfterViewInit  } from '@angular/core';
import { Location } from '@angular/common';
import { EventService } from '../event.service';
import * as XLSX from 'ts-xlsx';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { APPCONSTANTS } from '../../app-constants';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-delegates-to-event',
  templateUrl: './add-delegates-to-event.component.html',
  styleUrls: ['./add-delegates-to-event.component.css']
})

export class AddDelegatesToEventComponent implements OnInit, AfterViewInit {
  isLoadingResults = true;
  isRateLimitReached;
  showTable = false;
  // for form labels
  appConstants
  permissions;
  displayedColumns = [
    'c_b2c_uname',
    'c_email',
    'c_b2c_contact_number',
    'metadata',
    'actions'
  ];
  eventObservable;
  dataSource = new MatTableDataSource<any>();
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  arrayBuffer:any;
file:File;
jsonmails;
incomingfile(event) 
  {
  this.file= event.target.files[0]; 
  }

 Upload() {
   this.showTable = true;
      let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {type:"binary"});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            this.jsonmails = XLSX.utils.sheet_to_json(worksheet,{raw:true})
            console.log(this.jsonmails);
        }
        fileReader.readAsArrayBuffer(this.file);
}
 

 
  constructor(private _location: Location, private eventService: EventService, private router: Router) {
    
   }
  
  event;
  ngOnInit() {
    this.event = JSON.parse(localStorage.getItem("adddelegateevent"));
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    console.log(this.permissions)
    this.eventService.getDelegatesofEvents(this.event.id).toArray().subscribe(whdata =>{
      console.log(whdata);  
        whdata[0].forEach((individualUser, index, array) => {
          this.eventService.getEventMetadataofDelegate(this.event.id, individualUser.id).subscribe(data => {
              console.log(data)
              if(data.status == undefined){
                 individualUser['metadata'] = data 
              }else{
                individualUser['metadata'] = "Not Present";
              }
              this.dataSource.data = whdata[0];
              console.log(this.dataSource.data)
              if(index == array.length-1){
                this.isLoadingResults = false;
              }
          });
        });
        
    });
  
    
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
    console.log(this.paginator);
  }
  goBack() {
    this._location.back();
  }
  routeToUpdateUser(delegate){
    console.log(delegate)
    localStorage.setItem("updatedelid", JSON.stringify(delegate));
    this.router.navigate([]).then(result => {  window.open('#/update-b2c-user', '_blank'); });
  }
  routeToDelegateWallet(delegate){
    localStorage.setItem("walletdelid", JSON.stringify(delegate));
    this.router.navigate([]).then(result => {  window.open('#/delegate-wallet', '_blank'); });
  }
  assignEventtoDelegate(){
      const users = [];
      this.jsonmails.forEach(element => {
        users.push(element.email)
      });
      this.eventService.assignEventtoDelegates(this.event.id, users).subscribe(data =>{
        console.log(data)
        this.jsonmails.forEach(fullmails => {
        data.unassigned.forEach(unassignedmail => {
           
              if(unassignedmail == fullmails.email){
                fullmails.action = "Send Link"
              }
            });
        });
        this.jsonmails.forEach(fullmails => {
          data.assigned.forEach(assignedemail => {

                if(assignedemail == fullmails.email){
                  console.log("matched assigned "+assignedemail)
                  fullmails.action = "Assigned"
                }
              });
          });
      
      })
  }
  

}
