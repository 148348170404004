import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate, CanActivateChild {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const expectedPermission = route.data.expectedRole;
    const actualPermissions = JSON.parse(localStorage.getItem('permissions'));

    console.log(expectedPermission)
    console.log(actualPermissions)
    console.log(actualPermissions.indexOf(expectedPermission))
    if (actualPermissions.indexOf(expectedPermission) !== -1 ) {
      return true;
    }
    this.router.navigate(['unauthorized']);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
