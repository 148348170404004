import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AccessPointService } from '../access-point.service';
import { AccessPoint } from '../access-point';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { APPCONSTANTS } from '../../../app-constants';
// import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-access-points',
  templateUrl: './access-points.component.html',
  styleUrls: ['./access-points.component.css']
})
export class AccessPointsComponent implements OnInit, AfterViewInit  {
  permissions;
  appConstants;
  accessPoints: AccessPoint[] = [];
  showSpinner = true;
  apForm: FormGroup;
  selected = "AP";
  locId;
  // dataSource = new MatTableDataSource<AccessPoint>();
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accessPointService: AccessPointService,
    private _location: Location,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.appConstants = APPCONSTANTS;
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.setForm();
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  }
  setForm() {
    this.locId = this.route.snapshot.params['id'];
    console.log(this.locId);
    this.getAccessPoints();
    this.apForm = this.fb.group({
      c_location_id: [this.locId],
      c_name: [null]
    });
  }

  getAccessPoints() {
    const id = this.route.snapshot.params['id'];
    this.accessPointService.getAccessPoints(id, this.selected)
    .subscribe(data => {
      this.showSpinner = false;
      console.log(data);
      this.accessPoints = data;
      // this.dataSource.data = data;
    });
  }


  createAccessPoint() {
    this.apForm.value.c_location_id = this.locId;
    if (this.apForm.value.id !== null && this.apForm.value.id !== undefined) {
      this.accessPointService.updateAccessPoint(this.apForm.value, this.apForm.value.id)
      .subscribe(
      entity => {
        console.log('ap was updated');
        this.getAccessPoints();
      },
      err => {
        console.error(err);
      }
      );

    } else {

    this.accessPointService.createAccessPoint(this.apForm.value, this.selected)
      .subscribe(ap => {
        // this.successMessage = 'Loc was created!';
        console.log('ap was created');
        this.openSnackBar("Access Point Created");
        this.getAccessPoints();
        this.resetForm()
      },
      err => {
        // this.errorMessage = err;
        console.log(err);
      }
      );
    }
  }
  change(event)
  {
    if(event.isUserInput) {
      console.log(event.source.value, event.source.selected);
      this.selected = event.source.value;
      this.getAccessPoints();
    
    }
  }
  confirmDelete( id: string) {
    if (confirm(' Deleting ' + id + '. This action is irreversible. Are you sure? ')) {
      console.log('confirmed by user');
      
      // this.isLoadingResults = true;
      this.accessPointService.deleteAccessPoint(id)
        .subscribe(data => {        
            this.openSnackBar("Deleted");
             this.getAccessPoints();
        },
        err=>{
          this.openSnackBar(err)
        });
       
    } else {
      console.log('cancelled by user');
    }
    this.resetForm();
  }

  grabAccessPoint(id: string) {
    const locId = this.route.snapshot.params['id'];
    this.accessPointService.getAccessPoint(id)
      .subscribe(ap => {
        this.apForm = this.fb.group({
          id: [id],
          c_location_id: [locId],
          c_name: [ap.c_name],
        });
      });
  }


  resetForm() {
    this.apForm.reset();
    this.setForm();
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
