import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lost-wallet',
  templateUrl: './lost-wallet.component.html',
  styleUrls: ['./lost-wallet.component.css']
})
export class LostWalletComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
