import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Loc } from './loc';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../auth.service';
import { BURL } from '../back-end-urls';



@Injectable()
export class LocService {
  // this object will provide with human readable labels
  human_readable_obj = {
    id : "Id",
    c_org_id : "Organization Id",
    c_parent_id : "Parent Location Id",
    c_name : "Name",
    c_type : "Type",
    c_addess_id : "Address Id",
    c_img : "Image",
    c_date_created: "Date Created",
    c_date_modified: "Date Modified"
  };



  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

  // Observable Source
  private locCreatedSource = new Subject<Loc>();
  private locUpdatedSource = new Subject<Loc>();
  private locDeletedSource = new Subject();
  // Observable Stream
  locCreated$ = this.locCreatedSource.asObservable();
  locUpdated$ = this.locUpdatedSource.asObservable();
  locDeleted$ = this.locDeletedSource.asObservable();


  /**
   * get all loces
   */
  getLocs(orgid): Observable<Loc[]> {
    console.log(localStorage.getItem('appendurl'));
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    const org = {
      c_org_id: orgid
    }
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-organization/get-org-locations`, org ,{ headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  /**
   * get a single loc
   */
  getLoc(id: string): Observable<Loc> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`${BURL.mainURLYii}/t-locations/${id}`, { headers })
      .map(res => res.json())
      .do((res) => {
        localStorage.setItem('loc_obj', JSON.stringify(res));
      })
      .catch(this.handleError);
  }

  /**
   * delete a loc
   * @param id
   */
  deleteLoc(id: string): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.delete(`${BURL.mainURLYii}/t-locations/${id}`, { headers })
      .do(res => this.locDeleted())
      .catch(this.handleError);
  }

  createLoc(loc: Loc): Observable<Loc> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-locations`, loc, { headers })
      .map(res => res.json())
      .do(res => this.locCreated(loc))
      .catch(this.handleError);
  }


  updateLoc(loc: Loc, id: string): Observable<Loc> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.put(`${BURL.mainURLYii}/t-locations/${id}`, loc, { headers })
      .map(res => res.json())
      .do(res => this.locUpdated(loc))
      .catch(this.handleError);
  }

  locUpdated(loc: Loc) {
    this.locUpdatedSource.next(loc);
  }

  locCreated(loc: Loc) {
    this.locCreatedSource.next(loc);
  }

  locDeleted() {
    this.locDeletedSource.next();
  }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
