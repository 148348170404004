import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { User } from './user';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../auth.service';
import { BURL } from '../back-end-urls';
import { Organization } from '../organizations/organization';


@Injectable()
export class UserService {
  // this object will provide with human readable labels
  human_readable_obj = {
    id: "Id" ,
    c_email: "Email" ,
    c_username: "Username",
    c_firstname: "Firstname" ,
    c_lastname: "Lastname" ,
    c_password: "Password" ,
    c_contact_number: "Contact Number" ,
    c_user_image: "Image" ,
    c_ops_org_id: "Organization" ,
    c_date_created :"Date Created",
    c_date_modified:"Date Modified"
  };

  // private userUrl = this.authService.mainUrl + localStorage.getItem('appendurl');


  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

  usersChanged = new Subject<User>();
  private users: User[] = [];
  // Observable Source
  private userCreatedSource = new Subject<User>();
  private userUpdatedSource = new Subject<User>();
  private userDeletedSource = new Subject();

  private message = new Subject<any>();
  // Observable Stream
  userCreated$ = this.userCreatedSource.asObservable();
  userUpdated$ = this.userUpdatedSource.asObservable();
  userDeleted$ = this.userDeletedSource.asObservable();


  /**
   * get all users
   */
  getUsers(): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(`${BURL.mainURLYii}/t-ops-users`, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  getUsersByOrg(org_id: string): Observable<any>{
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const org = {
      c_org_id: org_id
    }
    console.log(org_id+", "+org);
    return this.http.post(`${BURL.mainURLYii}/t-organization/get-org-ops-users`, org, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  
  /**
   * get a single user
   */
  getUser(id: string): Observable<User> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`${BURL.mainURLYii}/t-ops-users/${id}`, { headers })
      .map(res => res.json())
      .do((res) => {
        this.users = res;
      }
    )
    // .map(this.toEntity)
    .catch(this.handleError);
  }

  /**
   * delete a user
   * @param id
   */
  deleteUser(id: string): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.delete(`${BURL.mainURLYii}/t-ops-users/${id}`, { headers })
      .do(res => this.userDeleted())
      .catch(this.handleError);
  }

  createUser(user: User): Observable<User> {
    const headers = new Headers();
    // hardcoding Organization for now
    let org: Organization = JSON.parse(localStorage.getItem('org'));
    //user.c_ops_org_id = org.id;
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-ops-users`, user, { headers })
      .map(res => res.json())
      .do(res => this.userCreated(user))
      .catch(this.handleError);
  }



  updateUser(user: User, id: string): Observable<User> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.put(`${BURL.mainURLYii}/t-ops-users/${id}`, user, { headers })
      .map(res => res.json())
      .do(res => this.userUpdated(user))
      .do(res => this.sendMessage(user))
      .catch(this.handleError);
  }
  sendMessage(user: User) {
    this.message.next(user);
}
  userUpdated(user: User) {
    this.userUpdatedSource.next(user);
  }
  getUpdatedUser(): Observable<User> {
    console.log("in here..............")
    return this.message.asObservable();
}
  userCreated(user: User) {
    this.userCreatedSource.next(user);
  }

  userDeleted() {
    this.userDeletedSource.next();
  }

  allocateLocations(data: {c_location_id: string[], c_ops_uid: string}) {
      const headers = new Headers();
      const token = localStorage.getItem('access_token');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);
      return this.http.post(`${BURL.mainURLYii}/t-ops-user/allocate-locations-to-ops-user`, data, { headers })
        .map(res => res.json())
        .catch(this.handleError);

  }

   getAllocatedLocations(uid) {
     const headers = new Headers();
     const token = localStorage.getItem('access_token');
     headers.append('Content-Type', 'application/json');
     headers.append('Authorization', `Bearer ${token}`);
     const data = {
      c_ops_uid: uid
    }
     return this.http.post(`${BURL.mainURLYii}/t-ops-user/get-allocated-locations-of-ops-user`, data, { headers })
       .map(res => res.json())
       .catch(this.handleError);
   }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }

}
