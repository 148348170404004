import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { LocService } from '../loc.service';
import { Loc } from '../loc';
import { Location } from '@angular/common';
import { OrganizationService } from '../../organizations/organization.service';
import {Organization} from '../../organizations/organization';
import { MatSnackBar } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-loc-update',
  templateUrl: './loc-update.component.html',
  styleUrls: ['./loc-update.component.css']
})
export class LocUpdateComponent implements OnInit {

  locForm: FormGroup;

  // for form labels
  hro = this.locService.human_readable_obj;

  isLoadingResults = true;
  id;

  c_type_enum_values = [
    {value: 'AUDITORIUM'},
    {value: 'COMPLEX'},
    {value: 'SPORTS CLUB'},
    {value: 'OFFICE'},
    {value: 'HOME'},
    {value: 'BUILDING'},
    {value: 'APARTMENT'},
    {value: 'VILLA'},
    {value: 'HOSTEL'},
    {value: 'GROUND'},
    {value: 'OTHER'}
  ];
  croppedImage : any = '';
  imageChangedEvent: any = '';
  selectedFile: Blob;
  image;

  organizations: Organization[];
  parentLocs: Loc[];
  loc: Loc;
  userOrg;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locService: LocService,
    private _location: Location,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userOrg = JSON.parse(localStorage.getItem('org'));
   // this.getChildOrganizations(this.userOrg['id']);
    //this.getLocs();
    this.locService.getLoc(this.id)
      .subscribe(loc => {
        this.loc = loc;
        this.getLocs(loc.c_org_id);
        this.locForm = this.fb.group({
          c_org_id : [loc.c_org_id, ],
          c_parent_id : [loc.c_parent_id],
          c_name : [loc.c_name, Validators.required],
          c_type : [loc.c_type, Validators.required],
          c_addess_id : [loc.c_addess_id],
          c_img : [loc.c_img],
          locimg: null,
        });
        this.isLoadingResults = false;
      });
  }
  filechange(event) {
    this.imageChangedEvent = event; 
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    var file:File =event.target.files[0];
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.croppedImage = myReader.result;
      console.log(this.croppedImage)
      this.loc.c_img = this.croppedImage.split(",")[1];
    }
    myReader.readAsDataURL(file);
  }
  
  updateLoc() {
    if(this.croppedImage != ''){
      this.locForm.patchValue({ c_img : this.croppedImage.split(",")[1] });
    
    }
    this.locService.updateLoc(this.locForm.value, this.id)
      .subscribe(
      entity => {
        console.log('loc was updated');
        this.router.navigate(['locs', `${this.route.snapshot.params['id']}`]);
      },
      err => {
        // this.errorMessage = err;
        console.error(err);
        this.openSnackBar(err);
      }
      );

  }

  getChildOrganizations(orgid) {

    this.organizationService.getChildOrganizations(orgid)
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
      });
  }

  getLocs(orgid) {
    this.locService.getLocs(orgid)
      .subscribe(locs => {
        this.parentLocs = locs;
        console.log(this.parentLocs);
        const index = this.parentLocs.findIndex(parentLoc => parentLoc.id == this.id)
        this.parentLocs.splice(index, 1); 
      });
  }

  goBack() {
    this._location.back();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

}
