import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AccessPointService } from '../access-point.service';
import { AccessPointGroup } from '../access-point-group';
import { AccessPoint } from '../access-point';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APPCONSTANTS } from '../../../app-constants';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-access-point-groups',
  templateUrl: './access-point-groups.component.html',
  styleUrls: ['./access-point-groups.component.css']
})
export class AccessPointGroupsComponent implements OnInit, AfterViewInit  {
  accessPointGroups: AccessPointGroup[] = [];
  appConstants;
  permissions;
  showSpinner = true;
  apgForm: FormGroup;
  locId;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accessPointService: AccessPointService,
    private _location: Location,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.appConstants = APPCONSTANTS;
    this.setForm();
  }

  ngAfterViewInit() {}

  setForm() {
    this.locId = this.route.snapshot.params['id'];
    console.log(this.locId);
    this.getAccessPointGroups();
    this.apgForm = this.fb.group({
      c_loc_id: [this.locId],
      c_name: [null],
    });
    console.log("setForm");
    console.log(this.apgForm.value);
    console.log("...");
  }
  getAccessPointGroups() {
    const id = this.route.snapshot.params['id'];
    this.accessPointService.getAccessPointGroups(id)
    .subscribe(data => {
      this.showSpinner = false;
      console.log(data);
      this.accessPointGroups = data;
    });
  }


  createAccessPointGroup() {
    this.apgForm.value.c_loc_id = this.locId;
    if (this.apgForm.value.id !== null && this.apgForm.value.id !== undefined) {
      this.accessPointService.updateAccessPointGroup(this.apgForm.value, this.apgForm.value.id)
      .subscribe(
      entity => {
        console.log('apg was updated');
        this.openSnackBar("Access Point Group Updated")
        this.getAccessPointGroups();
      },
      err => {
        console.error(err);
      }
      );

    } else {

    this.accessPointService.createAccessPointGroup(this.apgForm.value)
      .subscribe(apg => {
        // this.successMessage = 'Loc was created!';
        console.log('apg was created');
        this.openSnackBar("Access Point Group Created")
        this.resetForm()
        this.getAccessPointGroups();
      },
      err => {
        // this.errorMessage = err;
        console.log(err);
      }
      );
    }
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

  confirmDelete( id: string) {
    if (confirm(' Deleting ' + id + '. This action is irreversible. Are you sure? ')) {
      console.log('confirmed by user');
      // this.isLoadingResults = true;
      this.accessPointService.deleteAccessPointGroup(id)
        .subscribe(data => {
          this.openSnackBar("Deleted");
          this.getAccessPointGroups();
          
        },
        err => {
          this.openSnackBar(err);
        });
       
    } else {
      console.log('cancelled by user');
    }
    this.resetForm();
  }

  grabAccessPoint(id: string) {
    // const locId = this.route.snapshot.params['id'];
    this.accessPointService.getAccessPointGroup(id)
      .subscribe(apg => {
        this.apgForm = this.fb.group({
          id: [id],
          c_loc_id: [this.locId],
          c_name: [apg.c_name],
        });
      });
  }

  resetForm() {
    this.apgForm.reset();
    this.setForm();
  }

  openDialog(apg_id): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '400px',
      height: '600px',
      data: {apg_id, loc_id: this.locId}
    });

   
  }

  
  


}

export interface DialogData {
  apg_id: string;
  loc_id: string;
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
  styleUrls: ['./dialog-overview-example-dialog.css']
})
export class DialogOverviewExampleDialog {

  form: FormGroup;
  accessPoints: AccessPoint[] = [];
  myAccessPoints: AccessPoint[] = [];

  constructor(
    private fb: FormBuilder,
    private accessPointService: AccessPointService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    ngOnInit() {
     
      this.getAccessPointsWithApgId(this.data.apg_id);
      this.form = this.fb.group({
        c_apg_id: [this.data.apg_id],
        c_ap: [null, Validators.required]
      });
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
  change(event)
  {
    if(event.isUserInput) {
      console.log(event.source.value, event.source.selected);
      this.accessPointService.allocateorDeallocateAccessPoints(event.source.value, event.source.selected, this.data.apg_id)
      .subscribe(data => {
        console.log(data);
        this.openSnackBar(data.message)
      },
      err => {
        console.log(err);
      });
    }
  }
  getAccessPoints() {
    const id = this.data.loc_id;
    this.accessPointService.getAccessPoints(id, "AP")
    .subscribe(data => {
      console.log(data);
      this.accessPoints = data;
      // this.dataSource.data = data;
    });
  }

  getAccessPointsWithApgId(apgId) {
    this.accessPointService.getAccessPointsWithApgId(apgId)
    .subscribe(data => {
      console.log(data);
      this.myAccessPoints = data;
      this.getAccessPoints();
    },
    err => {
      console.log(err);
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
      this.dialogRef.close();
  }
}
