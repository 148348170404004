import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Organization } from './organization';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../auth.service';
import { BURL } from '../back-end-urls';



@Injectable()
export class OrganizationService {
  // this object will provide with human readable labels
  human_readable_obj = {
    id : "Id" ,
    c_org_name : "Organization Name" ,
    c_org_domain : "Organization Domain" ,
    c_org_type : "Organization Type" ,
    c_parent_org_id : "Parent Organization" ,
    c_org_img : "Image",
    c_address : "Address" ,
    c_city : "City" ,
    c_state : "State" ,
    c_pin : "Pincode" ,
    c_country : "Country" ,
    c_latlong : "Latitude/Longitude" ,
    c_date_created : "Date Created" ,
    c_date_modified : "Date Modified"
  };

  // private userUrl = this.authService.mainUrl + localStorage.getItem('appendurl');
  // debugger;

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

  // Observable Source
  private organizationCreatedSource = new Subject<Organization>();
  private organizationUpdatedSource = new Subject<Organization>();
  private organizationDeletedSource = new Subject();
  // Observable Stream
  organizationCreated$ = this.organizationCreatedSource.asObservable();
  organizationUpdated$ = this.organizationUpdatedSource.asObservable();
  organizationDeleted$ = this.organizationDeletedSource.asObservable();



   /**
   * get all Root organizationes
   */
  getAllRootOrganizations(): Observable<Organization[]> {
    console.log(localStorage.getItem('appendurl'));
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(`${BURL.mainURLYii}/t-organization/get-all-root-orgs`, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  /**
   * get a single organization
   */
  getOrganization(id: string): Observable<Organization> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`${BURL.mainURLYii}/t-organizations/${id}`, { headers })
      .map(res => res.json())
      // .map(this.toEntity)
      .catch(this.handleError);
  }

  getChildOrganizations(orgid){
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const orgID = {
      "c_org_id" : orgid
    }
    console.log(orgID)
    return this.http.post(`${BURL.mainURLYii}/t-organization/get-child-organizations`,orgID, { headers })
      .map(res => res.json())
      // .map(this.toEntity)
      .catch(this.handleError);
  }
  /**
   * delete a organization
   * @param id
   */
  deleteOrganization(id: string): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.delete(`${BURL.mainURLYii}/t-organizations/${id}`, { headers })
      .do(res => this.organizationDeleted())
      .catch(this.handleError);
  }

  createOrganization(organization: Organization): Observable<Organization> {
    console.log(organization);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-organizations`, organization, { headers })
      .map(res => res.json())
      .do(res => this.organizationCreated(organization))
      .catch(this.handleError);
  }


  updateOrganization(organization: Organization, id: string): Observable<Organization> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.put(`${BURL.mainURLYii}/t-organizations/${id}`, organization, { headers })
      .map(res => res.json())
      .do(res => this.organizationUpdated(organization))
      .catch(this.handleError);
  }

  organizationUpdated(organization: Organization) {
    this.organizationUpdatedSource.next(organization);
  }

  organizationCreated(organization: Organization) {
    this.organizationCreatedSource.next(organization);
  }

  organizationDeleted() {
    this.organizationDeletedSource.next();
  }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
