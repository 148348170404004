import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder  } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group(
      {
        old_password: [null, Validators.required],
        new_password: [null, [Validators.required, Validators.minLength(8)]],
        confirm_password: [null, [Validators.required, Validators.minLength(8)]],
      }, {validator: this.checkIfMatchingPasswords('new_password', 'confirm_password')}
    );
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value || passwordConfirmationInput.value === null) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
          return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  changePassword() {
    this.authService.changePassword(this.changePasswordForm.value)
    .subscribe(data => {
      console.log(data);
      this.openSnackBar('Password was successfully changed. Please login again!');
      this.logout();
    },
      err => {
        this.openSnackBar(err);
      }
    );

  }

  logout() {
    this.authService.logout();
    //.subscribe();
    this.router.navigate(['/signin']);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
