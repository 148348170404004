import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../wallet.service';
import { MatSnackBar } from '@angular/material';
import { APPCONSTANTS } from '../../../app-constants';
@Component({
  selector: 'app-delegate-wallet',
  templateUrl: './delegate-wallet.component.html',
  styleUrls: ['./delegate-wallet.component.css']
})
export class DelegateWalletComponent implements OnInit {

  constructor(private walletService: WalletService,private snackBar: MatSnackBar) { }
  delegate;
  event;
  wallet;
  walletBalance;
  allowCharge = false;
  isLoadingResults = true;
  permissions;
  appConstants;

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.event = localStorage.getItem("adddelegateevent");
    this.event = JSON.parse(this.event);
    this.getWalletId();
    this.delegate = localStorage.getItem("walletdelid");
    this.delegate = JSON.parse(this.delegate)
    
    

    //getdelegatefromlocalstorage
    //getwalletid
    //getbalanceviaemail
    //check permissions of add or discharge
    
  }
  getWalletId() {
    
    this.walletService.getWalletId(this.event['id'])
    .subscribe(data => {
      console.log(data);
      this.wallet = data;
      console.log(this.wallet)
      localStorage.setItem("wallet", JSON.stringify(this.wallet));
      console.log(localStorage.getItem("wallet"))
      this.getWalletBalanceByEmail();
     
    },
    err => {
      console.log(err);
    });
  }
  getWalletBalanceByEmail(){
    const data = {"c_event_id": this.event['id'], "c_wallet_id": this.wallet['id'], "c_b2c_email": this.delegate["c_email"]}
    this.walletService.getWalletBalanceByEmail(data)
    .subscribe(data => {
      console.log(data);
      this.isLoadingResults = false;
      if(data["status"] == true){
        this.walletBalance = data['message'];
        localStorage.setItem("walletBalance", JSON.stringify(data["message"]));
        //ready for charge
        this.allowCharge = true;
      }else{
        
        if(data["c_asset_id"]){
          //ready for charge
          this.allowCharge =  true;
          this.openSnackBar(data["message"]);
        }else{
          //no assetid for charge
          this.openSnackBar(data["message"]);
        }
      }
    },
    err => {
      console.log(err);
    });
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

}
