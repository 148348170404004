import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AssignUsersService } from './assign-users.service';
import { OrganizationService } from '../../../organizations/organization.service';
import {UserService} from '../../../users/user.service';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from "@angular/material";
@Component({
  selector: 'app-assign-users',
  templateUrl: './assign-users.component.html',
  styleUrls: ['./assign-users.component.css']
})
export class AssignUsersComponent implements OnInit {
  selectedValue= [];
  locId;
  userOrg;
  organizations;
  retrieveDataResolver;
  selected = [];
  allUsers = [];
  constructor( private route: ActivatedRoute, private assignUsers: AssignUsersService 
    , private organizationService: OrganizationService,
    private userService: UserService,public snackBar: MatSnackBar) {
      this.userOrg = JSON.parse(localStorage.getItem('org'));
    }

  ngOnInit() {
    this.locId = this.route.snapshot.params['id'];
    //getChildOrganization and its users
    //this.getChildOrganizations(this.userOrg['id']);

    //get already allocated users ofloc
    this.getAssignedUsersandLocs();
    
   
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
  change(event)
  {
    if(event.isUserInput) {
      console.log(event.source.value, event.source.selected);
      this.assignUsers.allocateordeallocateUsertoLocation(this.locId, event.source.value, event.source.selected).subscribe(data=>{
        console.log(data);
        this.openSnackBar(data.message);
      })
    }
  }
  onOut(){
    console.log("Value after losing focus  is",this.selected);
  }
  getChildOrganizationsandUsers(orgid) {
    
    this.organizationService.getChildOrganizations(orgid)
      .subscribe(orgs => {
        //console.log(orgs);
        this.organizations = orgs;
        this.organizations.push(this.userOrg);
        this.organizations.forEach(indorg => {
          //console.log(indorg)
          this.userService.getUsersByOrg(indorg['id']).subscribe(data => {
           // console.log(data);  
            data.forEach(inddata => {
              inddata.orgname = indorg["c_org_name"];
            });
            this.allUsers = this.allUsers.concat(data);
            console.log(JSON.stringify(this.allUsers))
            })
          
        });
        
       
      });
      
    
      
  }
 
  getAssignedUsersandLocs() {
    
      this.assignUsers.getAllocatedUsersofLoc(this.locId).subscribe(allocUsers => {
        console.log(allocUsers);
        if(allocUsers.status == undefined){
          allocUsers.forEach(indallocuser=>{
            this.selected.push(indallocuser.id);
          })
        }else{
          
          
        }
        this.getChildOrganizationsandUsers(this.userOrg['id']);
      })
    
  }

}
