import { Injectable } from "@angular/core";
import { Http, Response, Headers } from "@angular/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";
import { interval } from "rxjs/observable/interval";
import { map } from "rxjs-compat/operator/map";
import { switchMap, catchError } from "rxjs/operators";
import { timer, of } from "rxjs";
import { Subject } from "rxjs/Subject";
import { TagsData } from "./b2c-user/readtag";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { BURL } from "./back-end-urls";
@Injectable({
  providedIn: "root",
})
export class B2cUserServiceService {
  constructor(private http: Http) {}
  private eventCreatedSource = new Subject<TagsData>();
  // Observable Stream
  eventCreated$ = this.eventCreatedSource.asObservable();

  readTags(): Observable<TagsData> {
    const headers = new Headers();
    const token = localStorage.getItem("access_token");
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return timer(0, 100).pipe(
      switchMap((_) =>
        this.http
          .get(`${BURL.mainURLExpress}/readtags`, { headers })
          .map((res) => res.json())
          .do((res) => {
            let tagsData: TagsData;
            tagsData = res;
            this.eventCreated(tagsData);
          })
      ),
      catchError((error) => of(`Bad request: ${error}`))
    );

    // .catch(this.handleError);
  }
  eventCreated(tagsData: TagsData) {
    this.eventCreatedSource.next(tagsData);
  }
  getB2CUserDetails(id: string): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem("access_token");
    const data = {
      c_asset_id: id,
    };
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return this.http
      .post(
        `${BURL.mainURLYii}/t-b2c-user/get-b2c-user-details-with-device`,
        data,
        { headers }
      )
      .map((res) => res.json())
      .catch((error) => of(`Bad request: ${error}`));
  }
  getB2CUserbyId(id: string): Observable<any> {
    console.log(id);
    const headers = new Headers();
    const token = localStorage.getItem("access_token");
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return this.http
      .get(`${BURL.mainURLYii}/t-b2c-users/${id}`, { headers })
      .map((res) => res.json())
      .catch((error) => of(`Bad request: ${error}`));
  }
  updateUser(user: any, id: string): Observable<any> {
    console.log(id);
    const headers = new Headers();
    const token = localStorage.getItem("access_token");
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return this.http
      .put(`${BURL.mainURLYii}/t-b2c-users/${id}`, user, { headers })
      .map((res) => res.json())
      .catch((error) => of(`Bad request: ${error}`));
  }
}
