import { Component, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { User } from '../user';
import { UserService } from '../user.service';
import { MatSnackBar } from "@angular/material";
import { ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit {
  userForm: FormGroup;

  // for form labels
  hro = this.userService.human_readable_obj;

  user: User;
  isLoadingResults = true;
  id;
  croppedImage : any = '';
  imageChangedEvent: any = '';
  selectedFile: Blob;
  image;
  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];


    this.userService.getUser(this.id)
      .subscribe(user => {
        this.user = user;
        // form builder
        this.userForm = this.fb.group( {
            'c_firstname': [user.c_firstname, Validators.required],
            'c_lastname': [user.c_lastname, Validators.required],
            'c_username':[user.c_username, Validators.required],
            'c_email': [user.c_email, [ Validators.required, Validators.email]],
            'c_contact_number': [user.c_contact_number, Validators.required],
            'userimage' :[null],
            'c_user_image':[user.c_user_image],
          });

        console.log("status" + this.userForm.status);

        this.isLoadingResults = false;
      });
  }


  updateUser() {
    // this.successMessage = '';
    // this.errorMessage = '';
    console.log(this.userForm.value);
    if(this.croppedImage != ''){
      this.userForm.patchValue({ c_user_image : this.croppedImage.split(",")[1] });
    
    }
    this.userService.updateUser(this.userForm.value, this.id)
      .subscribe(
      entity => {
        console.log('user was updated');
        this.router.navigate(['users', `${this.id}`]);
      },
      err => {
        // this.errorMessage = err;
        console.error(err);
        this.openSnackBar(err);
      }
      );

  }

  goBack() {
    this._location.back();
  }
  filechange(event) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    var file:File =event.target.files[0];
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.croppedImage = myReader.result;
      console.log(this.croppedImage)
      this.user.c_user_image = this.croppedImage.split(",")[1];
    }
    myReader.readAsDataURL(file);
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
