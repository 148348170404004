import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatStepper } from '@angular/material/stepper';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  signinForm: FormGroup;
  domainForm: FormGroup;
  domains = [];
  isGoogleLogin = false;


  @ViewChild('stepper') stepper: MatStepper;

  successMessage = '';
  errorMessage = '';
  submitted = false;
  // spinner
  showSpinner = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,

  ) { }

  ngOnInit() {
    this.signinForm = this.fb.group({
      'username': [null, [Validators.required]],
      'password': [null, Validators.required],
    });
  }

  /**
   * Login a user
   */
  login() {
    this.submitted = true;
    this.showSpinner = true;
    this.authService.login(this.signinForm.value)
      .subscribe(data => {
        this.showSpinner = false;
        this.submitted = false;
        // this.getUserInfo();
        this.authService.listAllPermissions().subscribe(permissions => {
          this.authService.getOpsUserDetails().subscribe();
          this.authService.getAllUserRoles().subscribe();
        this.router.navigate(['']);
        });
         
        this.showSpinner = false;
        console.log(data);
      },
      err => {
        this.showSpinner = false;
        this.errorMessage = err;
        this.openSnackBar(err);
        console.log(err);
      }
      );

  }

  // getOrgInfo() {
  //   this.formTwoSubmitted = true;
  //   this.formTwoShowSpinner = true;
  //   this.authService.getOrgInfo(this.domainForm.value)
  //   .subscribe(data => {

  //     this.getUserInfo();
  //     console.log(data);
  //   },
  //   err => {
  //     this.showSpinner = false;
  //     this.errorMessage = err;
  //     console.log(err);
  //   }
  //   );
  // }

  // getUserInfo() {
  //   this.authService.getUserInfo()
  //   .subscribe(data => {
  //     this.router.navigate(['']);
  //     this.formTwoShowSpinner = false;
  //     this.formTwoSubmitted = false;
  //   });
  // }



//   sendToRestApiMethod(token: string): void {
//     this.authService.sendToRestApiMethod(token).subscribe(
//        data => {
//         // temporarily disabling the login form to excempt from validation
//         this.signinForm.disable();

//         // this.signinForm.patchValue({username: "dummy@dummy.com", password: "Dummy"});
//         this.stepper.next();
//         this.signinForm.enable();
//         // this.getUserInfo();
//         // this.router.navigate(['']);
//         // this.showSpinner = false;
//         console.log(data);
//         if (typeof data['data'] !== 'undefined' && data['data'].length > 0) {

//           this.authService.fcmInsert().subscribe(data =>{
//             console.log(data)
//           })
//           this.doesUserHaveAnyDomain = true;
//           this.domains = data['data'];
//         } else {
//           this.doesUserHaveAnyDomain = false;
//         }
//        }, err => {
//         console.log(err);
//        }
//     );
//  }


  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
