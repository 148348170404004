import { Component, OnInit, ViewChild, AfterViewInit  } from '@angular/core';
import { LocService } from '../loc.service';
import { Router } from '@angular/router';
import { Loc } from '../loc';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { APPCONSTANTS } from '../../app-constants';
import { OrganizationService } from '../../organizations/organization.service';
import 'rxjs/add/operator/toArray';

@Component({
  selector: 'app-loc-list',
  templateUrl: './loc-list.component.html',
  styleUrls: ['./loc-list.component.css']
})
export class LocListComponent implements OnInit, AfterViewInit {
  isLoadingResults = true;
  isRateLimitReached;
  roles;
  permissions;
  appConstants;
  userOrg;
  // for form labels
  hro = this.locService.human_readable_obj;
  organizations;

  displayedColumns = [
    'c_name',
    'c_type',
    'actions'
  ];
  locObservable;
  selectedOrg;
  dataSource = new MatTableDataSource<Loc>();


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private locService: LocService,
    public snackBar: MatSnackBar,
    public organizationService : OrganizationService
   ) {
    this.roles = JSON.parse(localStorage.getItem('roles'));
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.getLocs(this.userOrg['id']);
    this.selectedOrg = this.userOrg['id'];
    this.getChildOrganizations(this.userOrg['id']);
    
    
     
   }

  ngOnInit() {
  }
  organizationChange(event){
    this.selectedOrg = event.value;
    console.log(event.value);
    this.getLocs(event.value);
}
  getChildOrganizations(orgid) {
    this.organizationService.getChildOrganizations(orgid)
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
        this.organizations.push(this.userOrg);
      });
  }
  getAllRootOrgs() {
    this.organizationService.getAllRootOrganizations()
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
      });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
    console.log(this.paginator);
  }

  getLocs(orgid) {
    this.locObservable = this.locService.getLocs(orgid);
    this.locObservable.toArray()
      .subscribe(arr => {
        console.log(arr);
        this.dataSource.data = arr[0];
        this.isLoadingResults = false;
      });
  }

  confirmDelete( id: string) {
    if (confirm(' Deleting ' + id + '. This action is irreversible. Are you sure? ')) {
      console.log('confirmed by user');
      this.isLoadingResults = true;
      this.locService.deleteLoc(id)
        .subscribe(() => {
          this.getLocs(this.selectedOrg);
        },
        err => {
          this.getLocs(this.selectedOrg);
          this.openSnackBar(err);
        });
    } else {
      console.log('cancelled by user');
      this.openSnackBar('Cancelled by user!');
    }

  }
  
  getCreateLocForm() {
    this.router.navigate(['locs/create']);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
  designCanvas(locid: string){
    console.log("loc is is:"+locid)
    localStorage.setItem("canvaslocid", locid);
    this.router.navigate(['/canvas']);
  }
}
