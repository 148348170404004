import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder  } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OrganizationService } from '../../organizations/organization.service';
import { APPCONSTANTS } from '../../app-constants';
import { Organization } from '../../organizations/organization';
import { UserService } from '../user.service';
import { MatSnackBar } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  appConstants: any = '';
  roles: any = "";
  userForm: FormGroup;
  croppedImage : any = '';
  imageChangedEvent: any = '';
  selectedFile: Blob;
  permissions: any = "";
  organizations: Organization[];
  // for form labels
  hro = this.userService.human_readable_obj;
  userOrg;

  successMessage = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private _location: Location,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
   ) { }

  ngOnInit() {
    this.roles = JSON.parse(localStorage.getItem('roles'));
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    if(this.permissions.indexOf(this.appConstants.getAllRootOrgs) != -1){
      this.getAllRootOrgs();
     }else{
      this.getChildOrganizations(this.userOrg['id']);
     }
    this.userForm = this.fb.group({
      'c_firstname': [null, Validators.required],
      'c_lastname': [null, Validators.required],
      'c_username': [null, Validators.required],
      'c_email': [null, [ Validators.required, Validators.email]],
      'c_contact_number': [null, Validators.required],
      'c_password': [null, [Validators.required, Validators.minLength(8)]],
      'userimage' :[null],
      'c_user_image':[null],
      'c_ops_org_id':[null, Validators.required],
    });
  }
  filechange(event) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    var file:File =event.target.files[0];
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.croppedImage = myReader.result;
      console.log(this.croppedImage)
    }
    myReader.readAsDataURL(file);
   
  }

  getChildOrganizations(orgid) {
    this.organizationService.getChildOrganizations(orgid)
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
        //console.log(this.userOrg);
        this.organizations.push(this.userOrg);
        console.log(this.organizations);
      });
  }
  getAllRootOrgs() {
    this.organizationService.getAllRootOrganizations()
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
      });
  }
  createUser() {
     console.log(JSON.stringify(this.userForm.value));
    this.userForm.patchValue({ c_user_image : this.croppedImage.split(",")[1] });
    console.log(JSON.stringify(this.userForm.value));
    this.userService.createUser(this.userForm.value)
      .subscribe(user => {
        console.log('user was created');
        // navigate back to the index page
        this.router.navigate(['/users']);
      },
      err => {
        // this.errorMessage = err;
        console.log(err);
        this.openSnackBar(err);
      }
      );

  }
  
  

  goBack() {
    this._location.back();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

}
