import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { EventService } from '../event.service';
import { Event } from '../event';
import { Location } from '@angular/common';
import {LocService} from '../../locs/loc.service';
import {Loc} from '../../locs/loc';
import { MatSnackBar } from '@angular/material';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-event-update',
  templateUrl: './event-update.component.html',
  styleUrls: ['./event-update.component.css']
})
export class EventUpdateComponent implements OnInit {

  eventForm: FormGroup;

  // for form labels
  hro = this.eventService.human_readable_obj;
  locs: Loc[];
  event;
  c_type_enum_values = [
    {value: 'CONFERENCE'},
    {value: 'CONCERT'},
    {value: 'WEDDING'},
    {value: 'PARTY'},
    {value: 'CORPORATE'},
    {value: 'OTHER'},
    {value: 'FOREVER'},
  ];
  c_status_enum_values = [
    {value : 'ACTIVE'},
    {value : 'INACTIVE'},
    {value : 'ENABLED'},
    {value : 'DISABLED'},
    {value : 'LOCKED'}
  ];

  isLoadingResults = true;
  id;
  userOrg;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private _location: Location,
    private locService: LocService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userOrg = JSON.parse(localStorage.getItem('org')); 
    
    this.eventService.getEvent(this.id)
      .subscribe(event => {
        this.event = event;
        console.log(this.event)
        this.eventForm = this.fb.group({
          c_name: [event.c_name, Validators.required],
          c_type: [event.c_type, Validators.required],
          c_status: [event.c_status],
          c_date_from: [new Date(event.c_date_from), Validators.required],
          c_date_to: [new Date(event.c_date_to), Validators.required],
          c_location_id: [event.c_location_id],
        });
        this.getLocs(event.c_org_id);
        this.isLoadingResults = false;
      });
  }
  
  updateEvent() {
    debugger;
    this.eventForm.patchValue({c_date_from: this.datePipe.transform(this.eventForm.value.c_date_from, "yyyy-MM-dd HH:mm") });
    this.eventForm.patchValue({c_date_to: this.datePipe.transform(this.eventForm.value.c_date_to, "yyyy-MM-dd HH:mm") });
    
    this.eventService.updateEvent(this.eventForm.value, this.id)
      .subscribe(
      entity => {
        console.log('event was updated');
        this.router.navigate(['events', `${this.route.snapshot.params['id']}`]);
      },
      err => {
        // this.errorMessage = err;
        console.error(err);
        this.openSnackBar(err);
      }
      );

  }

  getLocs(orgid) {
    this.locService.getLocs(orgid)
      .subscribe(locs => {
        this.locs = locs;
      });
  }

  goBack() {
    this._location.back();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
