import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
import { Injectable } from '@angular/core';
import { BURL } from '../../../back-end-urls';
import { analyzeAndValidateNgModules } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})
export class AssignUsersService {

  constructor(private http: Http) { }
  getAllocatedUsersofLoc(id: any): Observable<any> {
    const data = {
      'c_location_id': parseInt(id)
    };
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-location/get-allocated-ops-users-of-location`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  getAssignedUsersofEvent(id: any): Observable<any> {
    const data = {
      'c_event_id': parseInt(id)
    };
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-event/get-assigned-ops-users-of-event`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  getAssignedUsersofWallet(id: any): Observable<any> {
    const data = {
      'c_wallet_id': parseInt(id)
    };
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-wallet/get-assigned-ops-users-of-wallet`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  allocateordeallocateUsertoLocation(locid:any, userid:any, allocate:boolean){
    const data = {
      'c_location_id': locid,
      'c_ops_uid':[userid]
    };
    let url = "";
    if(allocate == true){
       url = `${BURL.mainURLYii}/t-location/allocate-ops-users-to-location`;
    }else{
      url = `${BURL.mainURLYii}/t-location/de-allocate-ops-users-from-location`;
    }
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(url, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  allocateordeallocateUsertoEvent(eventid:any, userid:any, allocate:boolean){
    const data = {
      'c_event_id': eventid,
      'users':[userid]
    };
    let url = "";
    if(allocate == true){
       url = `${BURL.mainURLYii}/t-event/assign-ops-users-to-event`;
    }else{
      url = `${BURL.mainURLYii}/t-event/un-assign-ops-users-from-event`;
    }
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(url, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  allocateordeallocateUsertoWallet(eventid:any, userid:any, allocate:boolean){
    const data = {
      'c_wallet_id': eventid,
      'users':[userid]
    };
    let url = "";
    if(allocate == true){
       url = `${BURL.mainURLYii}/t-wallet/assign-ops-users-to-wallet`;
    }else{
      url = `${BURL.mainURLYii}/t-wallet/un-assign-ops-users-from-wallet`;
    }
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(url, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
