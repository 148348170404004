import { Component, OnInit, ViewChild, AfterViewInit  } from '@angular/core';
import { EventService } from '../event.service';
import { Router } from '@angular/router';
import { Event } from '../event';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import 'rxjs/add/operator/toArray';
import { OrganizationService } from '../../organizations/organization.service';
import { APPCONSTANTS } from '../../app-constants';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit, AfterViewInit {
  isLoadingResults = true;
  isRateLimitReached;
  
  // for form labels
  hro = this.eventService.human_readable_obj;


  displayedColumns = [
    'c_name',
    'c_type',
    'c_status',
    'c_date_from',
    'c_date_to',
    'c_location_id',
    'actions'
  ];
  eventObservable;
  dataSource = new MatTableDataSource<Event>();
  roles;
  permissions;
  appConstants;
  userOrg;
  organizations;
  organizationObservable: any ='';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedOrg;
  constructor(
    private router: Router,
    private eventService: EventService,
    public snackBar: MatSnackBar,
    private organizationService: OrganizationService
   ) {
     //this.getEvents();
   }

  ngOnInit() {
    this.roles = JSON.parse(localStorage.getItem('roles'));
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.selectedOrg = this.userOrg['id'];
    if(this.permissions.indexOf(this.appConstants.getOrgEvents)!==-1){
      this.getEvents(this.userOrg['id']);
    
    }else{
      this.getAssignedEvents(this.userOrg['id']);
    }
    this.getChildOrganizations(this.userOrg['id']);
    
  }
  
  getChildOrganizations(orgid) {
    this.organizationService.getChildOrganizations(orgid)
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
        this.organizations.push(this.userOrg)
      });
  }
  getAllRootOrgs() {
    this.organizationService.getAllRootOrganizations()
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
    console.log(this.paginator);
  }

  getEvents(orgid) {
    this.eventObservable = this.eventService.getEvents(orgid);
    this.eventObservable.toArray()
      .subscribe(arr => {
        console.log(arr);
        this.dataSource.data = arr[0];
        this.isLoadingResults = false;
      });
  }
  
  getAssignedEvents(orgid) {
    this.eventObservable = this.eventService.getAssignedEvents(orgid);
    this.eventObservable.toArray()
      .subscribe(arr => {
        console.log(arr);
        this.dataSource.data = arr[0];
        this.isLoadingResults = false;
      });
  }
  routeToAddDelegate(event){
    console.log(event)
    localStorage.setItem("adddelegateevent", JSON.stringify(event));
    this.router.navigate(['/add-delegate']);

  }
  organizationChange(event){
    this.selectedOrg = event.value;
    console.log(event.value);
    this.getEvents(event.value);
 }
  confirmDelete( id: string) {
    if (confirm(' Deleting ' + id + '. This action is irreversible. Are you sure? ')) {
      console.log('confirmed by user');
      this.isLoadingResults = true;
      this.eventService.deleteEvent(id)
        .subscribe(() => {
          this.getEvents(this.selectedOrg);
        },
        err => {
          this.getEvents(this.selectedOrg);
          this.openSnackBar(err);
        });
    } else {
      console.log('cancelled by user');
      this.openSnackBar('Cancelled by user');
    }

  }

  getCreateEventForm() {
    this.router.navigate(['events/create']);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
