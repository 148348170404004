import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Router, Params, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../auth.service";
import { Location } from "@angular/common";
import { MatSnackBar } from "@angular/material";


@Component({
  selector: 'app-grant-revoke-role',
  templateUrl: './grant-revoke-role.component.html',
  styleUrls: ['./grant-revoke-role.component.css']
})
export class GrantRevokeRoleComponent implements OnInit {

  grantForm: FormGroup;
  revokeForm: FormGroup;
  id: number;
  sysRoles: string[];
  userRoles: string[];
  permissions;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private fb: FormBuilder,
    public snackBar: MatSnackBar

  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params["id"];
    this.getSysRoles();
    this.getUserRoles();
    this.getAllUserPermissions();
    this.grantForm = this.fb.group({
      role: [null, Validators.required],
      user: [this.id, Validators.required]
    });

    this.revokeForm = this.fb.group({
      role: [null, Validators.required],
      user: [this.id, Validators.required]
    });
  }
  getAllUserPermissions(){
    this.authService.listAllPermissionsbyUid(this.id).subscribe(permissions => {
      this.permissions = permissions;
      console.log(permissions)
    });
  }
  revokeRole() {
    this.authService.revokeRoleFromUser(this.revokeForm.value)
    .subscribe(data => {
        console.log(data);
        if(data.status == false){
          this.openSnackBar(data.message)
        }else{
          this.openSnackBar("Role revoked!");
          this.getUserRoles();
        
        }
        // this.revokeForm.reset();
      },
      err => {
        console.log(err);
        this.openSnackBar(err);

      });
  }

  grantRole() {
    this.authService.grantRoleToUser(this.grantForm.value)
    .subscribe(data => {
      console.log(data);
      debugger;
      if(data.status == false){
        this.openSnackBar(data.message)
      
      }else{
        this.openSnackBar("Role granted!");
        this.getUserRoles();
       
      }
      // this.grantForm.reset();
    },
    err => {
      console.log(err);
      this.openSnackBar(err);

    });
  }


  getSysRoles() {
    this.authService.getAllSystemRoles()
      .subscribe(roles => {
        this.sysRoles = roles;
        // this.showSpinner = false;
        console.log(this.sysRoles);
      });
  }



  getUserRoles() {
    this.authService.getAllUserRolesofUId(this.id)
      .subscribe(roles => {
        this.userRoles = roles;
        // this.showSpinner = false;
        console.log(this.userRoles);
      });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
