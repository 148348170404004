import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Event } from './event';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../auth.service';
import { BURL } from '../back-end-urls';


@Injectable()
export class EventService {
  // this object will provide with human readable labels
  human_readable_obj = {
    id: "Id",
    c_org_id: "Organization",
    c_location_id: "Location",
    c_name: "Event Name",
    c_type: "Type",
    c_date_created: "Created at",
    c_date_modified: "Modified at",
    c_status: "Status",
    c_date_from: "From(date)",
    c_date_to: "To(date)"
  };

  // private userUrl = this.authService.mainUrl + localStorage.getItem('appendurl');
  // debugger;

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

  // Observable Source
  private eventCreatedSource = new Subject<Event>();
  private eventUpdatedSource = new Subject<Event>();
  private eventDeletedSource = new Subject();
  // Observable Stream
  eventCreated$ = this.eventCreatedSource.asObservable();
  eventUpdated$ = this.eventUpdatedSource.asObservable();
  eventDeleted$ = this.eventDeletedSource.asObservable();

  createEvent(event: Event): Observable<Event> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-events`, event, { headers })
      .map(res => res.json())
      .do(res => this.eventCreated(event))
      .catch(this.handleError);
  }
  /**
   * get all eventes
   */
  getEvents(orgid): Observable<Event[]> {
    console.log(localStorage.getItem('appendurl'));
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const org_id = {
      'c_org_id': orgid
    }
    return this.http.post(`${BURL.mainURLYii}/t-organization/get-org-events`, org_id,{ headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  getAssignedEvents(userid): Observable<Event[]>{
    console.log(localStorage.getItem('appendurl'));
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
   
    return this.http.get(`${BURL.mainURLYii}/t-event/get-assigned-active-events-of-ops-user`, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  getDelegatesofEvents(eventid): Observable<Event[]> {
    console.log(localStorage.getItem('appendurl'));
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const data = {
      'c_event_id': eventid
    }
    return this.http.post(`${BURL.mainURLYii}/t-event/get-assigned-b2c-users-of-event`, data,{ headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  /**
   * get a single event
   */
  getEvent(id: string): Observable<Event> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`${BURL.mainURLYii}/t-events/${id}`, { headers })
      .map(res => res.json())
      .do((res) => {
        console.log(JSON.stringify(res));
        localStorage.setItem('event_obj', JSON.stringify(res));
      })
      // .map(this.toEntity)
      .catch(this.handleError);
  }

  getB2cUsers(): Observable<Event> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`${BURL.mainURLYii}/t-b2c-users`, { headers })
      .map(res => res.json())
      .do((res) => {
        console.log(JSON.stringify(res));
        
      })
      // .map(this.toEntity)
      .catch(this.handleError);
  }

  /**
   * delete a event
   * @param id
   */
  deleteEvent(id: string): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.delete(`${BURL.mainURLYii}/t-events/${id}`, { headers })
      .do(res => this.eventDeleted())
      .catch(this.handleError);
  }
  assignEventtoDelegates(eventid, users):Observable<any>{
    console.log(localStorage.getItem('appendurl'));
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const postdata = {
      'c_event_id': eventid,
      'users':users
    }
    return this.http.post(`${BURL.mainURLYii}/t-event/assign-b2c-users-to-event`, postdata,{ headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  
  


  updateEvent(event: Event, id: string): Observable<Event> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.put(`${BURL.mainURLYii}/t-events/${id}`, event, { headers })
      .map(res => res.json())
      .do(res => this.eventUpdated(event))
      .catch(this.handleError);
  }
  getEventPartners(id: string){
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    const data = {
      'c_event_id': parseInt(id)
    };
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-event/get-event-partners`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  getEventDelegates(id: string){
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    const data = {
      'c_event_id': parseInt(id)
    };
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-event/get-assigned-b2c-users-of-event`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  getEventMetadataofDelegate(eventid: string, userid:string){
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    const data = {
      "c_event_id":eventid,
	    "c_b2c_uid":userid

    };
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-b2c-user/get-event-md-of-b2c-user`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  eventUpdated(event: Event) {
    this.eventUpdatedSource.next(event);
  }

  eventCreated(event: Event) {
    this.eventCreatedSource.next(event);
  }

  eventDeleted() {
    this.eventDeletedSource.next();
  }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
