import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { B2cUserServiceService } from '../../b2c-user-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-update-b2c-user',
  templateUrl: './update-b2c-user.component.html',
  styleUrls: ['./update-b2c-user.component.css']
})
export class UpdateB2cUserComponent implements OnInit {
  delegate;
  user;
  isLoadingResults = true;
  userForm;
  croppedImage : any = '';
  imageChangedEvent: any = '';
  selectedFile: Blob;
  image;
  constructor(private b2cuserser : B2cUserServiceService,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.delegate = localStorage.getItem("updatedelid");
    this.delegate = JSON.parse(this.delegate)
    console.log(this.delegate)
    this.b2cuserser.getB2CUserbyId(this.delegate.id).subscribe(data => {
      this.isLoadingResults = false;
      console.log(data)
      this.user = data;
        this.userForm = this.fb.group({
          c_b2c_uname : [this.user.c_b2c_uname, Validators.required] ,
          c_email : [this.user.c_email.trim(), [Validators.required,  Validators.email]] ,
          c_gender : [this.user.c_gender, Validators.required] ,
          c_dob : [this.user.c_dob, Validators.required] ,
          c_b2c_image : [this.user.c_b2c_image],
          userimg: null,
          c_b2c_contact_number : [this.user.c_b2c_contact_number, Validators.required],
          c_b2c_address: [this.user.c_b2c_address]

    })
    
    
    });
  }
  goBack() {
    this._location.back();
  }
  filechange(event) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    var file:File =event.target.files[0];
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.croppedImage = myReader.result;
      console.log(this.croppedImage)
      this.user.c_b2c_image = this.croppedImage.split(",")[1];
    }
    myReader.readAsDataURL(file);
  
   
  }
  updateUser(){
    this.isLoadingResults = true;
    console.log(this.userForm.value);
    this.userForm.patchValue({ c_dob :  this.datePipe.transform(this.userForm.value.c_dob, "yyyy-MM-dd") });
    if(this.croppedImage != ""){
      this.userForm.patchValue({ c_b2c_image : this.croppedImage.split(",")[1] });
    }
    this.b2cuserser.updateUser(this.userForm.value, this.delegate.id)
      .subscribe(
      entity => {
        this.isLoadingResults = false;
       console.log(entity);
       if(entity.id !== undefined){
         this.openSnackBar("User Updated")
         this.router.navigate(["/add-delegate"]);
       }else{
         this.openSnackBar(entity);
         
       }
      });

  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

}
