import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../organization.service';
import { Organization } from '../organization';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-organization-view',
  templateUrl: './organization-view.component.html',
  styleUrls: ['./organization-view.component.css']
})
export class OrganizationViewComponent implements OnInit {
  image = null;
  showSpinner = true;
    displayedColumns = ['name', 'details'];
    ELEMENT_DATA = [];

  dataSource = new MatTableDataSource<Element>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private _location: Location) { }

  ngOnInit() {
    // grab the id from the url
    let id = this.route.snapshot.params['id'];
    console.log(id);
    this.organizationService.getOrganization(id)
      .subscribe(organization => {
        console.log(organization);

        for (let i in organization) {
          console.log(i);
          let key = this.organizationService.human_readable_obj[i];
          if(i == "c_org_img"){
            if(organization[i] != ""){
            this.image = "data:image/jpeg;base64"+","+organization[i];
            console.log(this.image)
            } 
          }else{
            this.ELEMENT_DATA.push({ name: key, details: organization[i] });
          }
          
        }

        console.log("Elements : " + JSON.stringify(this.ELEMENT_DATA));
        this.dataSource.data = this.ELEMENT_DATA;
        this.showSpinner = false;
      });
  }

  goBack() {
    this._location.back();
  }



}
