import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../wallet.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EventService } from '../../event.service';
import { Organization } from 'src/app/organizations/organization';
import {Wallet} from '../../wallet';
import { MatSnackBar } from '@angular/material';
import { APPCONSTANTS } from  '../../../app-constants';
import { AssignUsersService} from '../../../locs/loc-view/assign-users/assign-users.service'
import {UserService} from '../../../users/user.service';
@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.css']
})
export class WalletsComponent implements OnInit {
  walletForm: FormGroup;
  selectedUsers = [];
  eventVendors: Organization[];
  walletVendors: Organization[]=[];
  wallet: Wallet;
  walletCreated = false;
  process = false;
  eventObj;
  id;
  permissions;
  appConstants;
  selected = [];
  constructor(
    private walletService: WalletService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private userService: UserService,
    private assignUserService: AssignUsersService
  ) { 
    this.id = this.route.snapshot.params['id'];
    this.getEventOrg();
    
  }

  ngOnInit() {
    this.appConstants = APPCONSTANTS;
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.walletForm = this.fb.group({
      c_org_id:[0],
      c_wallet_name: [null, Validators.required],
      c_event_id: [this.id]
    });

   
    
    this.walletService.vendorAddedToEvent$
    .subscribe(() => {
      
      this.getWalletVendors(this.wallet.id);
      //this.getEventVendors({c_event_id: this.id})
    });
    
  }
  change(event)
  {
    console.log("calledddddddd.....................")
    
    if(event.isUserInput) {
      this.process = true;
      console.log(event.source.value, event.source.selected);
      this.walletService.associateorunassociateVendorWithWallet(this.wallet.id, event.source.value, event.source.selected)
      .subscribe(data =>
        {
          console.log(data);
          this.getWalletId();
          this.openSnackBar('Success!');
          this.process = false;
        },
        err => {
          console.log(err);
          this.openSnackBar(err);
        }
        );
     
    }
  }
  changeUser(event)
  {
    if(event.isUserInput) {
      console.log(event.source.value, event.source.selected);
      this.assignUserService.allocateordeallocateUsertoWallet(this.wallet.id, event.source.value, event.source.selected)
      .subscribe(data =>
        {
          console.log(data);
          this.getWalletId();
          this.openSnackBar('Success!');
        },
        err => {
          console.log(err);
          this.openSnackBar(err);
        }
        );
     
    }
  }
  getEventOrg(){
    this.eventService.getEvent(this.id).subscribe(event => {
      console.log(event);
      this.eventObj = event;
      this.getWalletId();
    });
  }

  createWallet() {
    this.walletForm.patchValue({c_org_id: this.eventObj['c_org_id']})
    this.walletService.createWallet(this.walletForm.value)
    .subscribe(
      data => {
        console.log(data);
        this.walletCreated =true;
        this.getWalletId();
      },
      err => {
        console.log(err);
      }
    );
  }
  getEventVendors(eventId) {
    this.walletService.getEventVendor({c_event_id: eventId })
    .subscribe(
      eventVendors => {
        this.eventVendors = eventVendors;
      }
    );
  }
  getWalletVendors(walletId) {
    this.walletService.getWalletVendor({c_wallet_id: walletId})
    .subscribe(vendors => {
      this.selected = [];
      this.walletVendors = vendors;
      console.log(this.walletVendors['status']);
      if(this.walletVendors['status'] == undefined){
        this.walletVendors.forEach(vendor => {
          if(this.selected.indexOf(vendor.id) > -1){

          }else{
            this.selected.push(vendor.id);
          
          }
          this.userService.getUsersByOrg(""+vendor.id)
          .subscribe(vendorUsers=>{
            vendor.users = vendorUsers;
          })
        })
        console.log(this.selected);
        this.getEventVendors({c_event_id: this.id});
      }else{
        this.getEventVendors({c_event_id: this.id});
      }
      
    },
    err => {
      console.log(err);
    });
  }
  getWalletId() {
    this.selectedUsers = []
    this.walletService.getWalletId(this.id)
    .subscribe(data => {
      console.log(data);
      this.wallet = data;
      this.getWalletVendors(this.wallet.id);
      this.assignUserService.getAssignedUsersofWallet(this.wallet.id).subscribe(data=>{
        data.forEach(element => {
          this.selectedUsers.push(element.id);
        });
      })
    },
    err => {
      console.log(err);
    });
  }
  

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
