import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router, Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group(
      {
        email: [null, [ Validators.required, Validators.email]]
      }
    );
  }

  forgotPassword() {
    alert('TBD');
    // TBD
    // this.authService.forgotPassword(this.forgotPasswordForm.value.email)
    // .subscribe(res => {
    //   console.log(res);
    //   this.router.navigate(['/signin']);

    // });
  }

}
