import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { EventService } from '../event.service';
import { Event } from '../event';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.css']
})
export class EventViewComponent implements OnInit {

  showSpinner = true;
  displayedColumns = ['name', 'details'];
  ELEMENT_DATA = [];

  dataSource = new MatTableDataSource<Element>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private _location: Location) { }

  ngOnInit() {
    // grab the id from the url
    let id = this.route.snapshot.params['id'];
console.log(id);
    this.eventService.getEvent(id)
      .subscribe(event => {
        console.log(event);

        for (let i in event) {
          console.log(i);
          let key = this.eventService.human_readable_obj[i];
          this.ELEMENT_DATA.push({ "name": key, "details": event[i] });
        }

        console.log("Elements : " + JSON.stringify(this.ELEMENT_DATA));
        this.dataSource.data = this.ELEMENT_DATA;
        this.showSpinner = false;
      });
  }

  goBack() {
    this._location.back();
  }



}
