import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {  SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
 export const MY_MOMENT_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
  parseInput: 'YYYY-MM-DD HH:mm:ss',
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'}
 };
 import {DatePipe} from '@angular/common';

import {
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatButtonModule,
  MatIconModule,
  MatSortModule,
  MatMenuModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatCardModule,
  MatSelectModule,
  MatDividerModule,
  MatExpansionModule,
  MatTabsModule,
  MatDatepickerModule,
  MatGridListModule,
  MatDialogModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatRadioModule,
  MAT_OPTION_PARENT_COMPONENT
} from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';// Components
import { SigninComponent } from './signin/signin.component';
import { AboutComponent } from './about/about.component';
import { ContentComponent } from './content/content.component';
import { WelcomeHomeComponent } from './content/welcome-home/welcome-home.component';
import { NotFoundComponent } from './not-found/not-found.component';

 

import { UsersComponent } from './users/users.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserUpdateComponent } from './users/user-update/user-update.component';
import { UserViewComponent } from './users/user-view/user-view.component';

import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { NotAllowedComponent } from "./not-allowed/not-allowed.component";
import { ImageCropperModule } from 'ngx-image-cropper';
import {MatChipsModule} from '@angular/material/chips';
// auth service
import { AuthService } from './auth.service';
import { UserService } from './users/user.service';
import { OrganizationService } from './organizations/organization.service';
import { LocService } from './locs/loc.service';
import { EventService } from './events/event.service';
import { AccessPointService } from './locs/loc-view/access-point.service';
import { WalletService } from './events/wallet.service';
import { CustomRoleService } from './custom-roles/custom-role.service';
// Guards
import { AuthGuardService } from './auth-guard.service';
import { RoleGuardService } from './role-guard.service';



import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationCreateComponent } from './organizations/organization-create/organization-create.component';
import { OrganizationListComponent } from './organizations/organization-list/organization-list.component';
import { OrganizationUpdateComponent } from './organizations/organization-update/organization-update.component';
import { OrganizationViewComponent } from './organizations/organization-view/organization-view.component';
import { LocsComponent } from './locs/locs.component';
import { LocCreateComponent } from './locs/loc-create/loc-create.component';
import { LocListComponent } from './locs/loc-list/loc-list.component';
import { LocUpdateComponent } from './locs/loc-update/loc-update.component';
import { LocViewComponent } from './locs/loc-view/loc-view.component';
import { EventsComponent } from './events/events.component';
import { EventCreateComponent } from './events/event-create/event-create.component';
import { EventListComponent } from './events/event-list/event-list.component';
import { EventUpdateComponent } from './events/event-update/event-update.component';
import { EventViewComponent } from './events/event-view/event-view.component';
import { AccessPointsComponent } from './locs/loc-view/access-points/access-points.component';
import { AccessPointGroupsComponent } from './locs/loc-view/access-point-groups/access-point-groups.component';
import { LocationCanvasComponent } from './locs/loc-view/location-canvas/location-canvas.component';
import { DialogOverviewExampleDialog } from './locs/loc-view/access-point-groups/access-point-groups.component';
import { CustomRolesComponent } from './custom-roles/custom-roles.component';
import { GrantRevokeRoleComponent } from './users/user-view/grant-revoke-role/grant-revoke-role.component';
import { VendorsComponent } from './events/event-view/vendors/vendors.component';
import { WalletsComponent } from './events/event-view/wallets/wallets.component';
import { AllocateLocationComponent } from './users/user-view/allocate-location/allocate-location.component';
import { CanvasComponent } from './canvas/canvas.component';
import { AssignUsersComponent } from './locs/loc-view/assign-users/assign-users.component';
import { AssignEventUsersComponent } from './events/event-view/assign-event-users/assign-event-users.component';
import { AddDelegatesToEventComponent } from './events/add-delegates-to-event/add-delegates-to-event.component';
import { B2cUserComponent } from './b2c-user/b2c-user.component';
import { UpdateB2cUserComponent } from './b2c-user/update-b2c-user/update-b2c-user.component';
import { DelegateWalletComponent } from './events/add-delegates-to-event/delegate-wallet/delegate-wallet.component';
import { ChargeWalletComponent } from './events/add-delegates-to-event/delegate-wallet/charge-wallet/charge-wallet.component';
import { DischargeWalletComponent } from './events/add-delegates-to-event/delegate-wallet/discharge-wallet/discharge-wallet.component';
import { LostWalletComponent } from './events/add-delegates-to-event/delegate-wallet/lost-wallet/lost-wallet.component';
//const config: SocketIoConfig = { url: '192.168.0.200:200', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    ContentComponent,
    NotFoundComponent,
    WelcomeHomeComponent,
    AboutComponent,
    UsersComponent,
    UserCreateComponent,
    UserListComponent,
    UserViewComponent,
    ForgotPasswordComponent,
    NotAllowedComponent,
    NotFoundComponent,
    ChangePasswordComponent,
    UserUpdateComponent,
    OrganizationsComponent,
    OrganizationCreateComponent,
    OrganizationListComponent,
    OrganizationUpdateComponent,
    OrganizationViewComponent,
    LocsComponent,
    LocCreateComponent,
    LocListComponent,
    LocUpdateComponent,
    LocViewComponent,
    EventsComponent,
    EventCreateComponent,
    EventListComponent,
    EventUpdateComponent,
    EventViewComponent,
    AccessPointsComponent,
    AccessPointGroupsComponent,
    LocationCanvasComponent,
    DialogOverviewExampleDialog,
    CustomRolesComponent,
    GrantRevokeRoleComponent,
    VendorsComponent,
    WalletsComponent,
    AllocateLocationComponent,
    CanvasComponent,
    AssignUsersComponent,
    AssignEventUsersComponent,
    AddDelegatesToEventComponent,
    B2cUserComponent,
    UpdateB2cUserComponent,
    DelegateWalletComponent,
    ChargeWalletComponent,
    DischargeWalletComponent,
    LostWalletComponent,
  ],
  entryComponents: [DialogOverviewExampleDialog],
  imports: [
    BrowserModule,
    MatChipsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatCardModule,
    MatSelectModule,
    MatDividerModule,
    MatExpansionModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatGridListModule,
    MatDialogModule,
    MatTooltipModule,
    MatAutocompleteModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpModule,
    ImageCropperModule,
    MatRadioModule,
   // SocketIoModule.forRoot(config)
  ],
  providers: [
    AuthService,
    UserService,
    OrganizationService,
    LocService,
    EventService,
    AccessPointService,
    WalletService,
    CustomRoleService,
    AuthGuardService,
    RoleGuardService,
    DatePipe,
    {provide: OWL_DATE_TIME_LOCALE, useValue: MY_MOMENT_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
