import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../wallet.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EventService } from '../../event.service';
import { Organization } from 'src/app/organizations/organization';
import { MatSnackBar } from '@angular/material';
import { OrganizationService } from 'src/app/organizations/organization.service';
import { APPCONSTANTS } from '../../../app-constants';
import { AssignUsersService} from '../../../locs/loc-view/assign-users/assign-users.service'
import {UserService} from '../../../users/user.service';
@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit {
  assocVendorEventForm: FormGroup;
  appConstants;
  permissions;
  process = false;
  orgVendors;
  id: string;
  selected = [];
  allUsers = [];
  selectedUsers = [];
  userOrg;
  eventObj;
  constructor(
    private walletService: WalletService,
    private organizationService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private userService: UserService,
    private assignUserService: AssignUsersService
  ) { 
   

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.appConstants = APPCONSTANTS;
    this.eventObj = JSON.parse(localStorage.getItem('event_obj'));
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.getEventPartners();
    this.getEventOrg();
    
    
    
   
  }
  getAssociatedUsersofEvent(){
    this.assignUserService.getAssignedUsersofEvent(this.id)
    .subscribe(data => {
      data.forEach(element => {
        this.selectedUsers.push(element.id);
      });
      this.getusersofRefreshedPatners();
    })
    console.log(this.selectedUsers);
  }
  change(event)
  {
    
    if(event.isUserInput) {
      this.process = true;
    console.log("change called ------------------")
      console.log(event.source.value, event.source.selected);
      this.walletService.associateorunassociateVendorWithEvent(this.eventObj['id'], event.source.value, event.source.selected)
      .subscribe(data =>
        {
          console.log(data);
          this.openSnackBar('Success!');
          this.process = false;
          //getusersofrefreshedpartners
          //this.getusersofRefreshedPatners();
          
        },
        err => {
          console.log(err);
          this.openSnackBar(err);
        }
        );
    }
  }
  changeUser(event)
  {
    if(event.isUserInput) {
      console.log(event.source.value, event.source.selected);
      this.assignUserService.allocateordeallocateUsertoEvent(this.eventObj['id'], event.source.value, event.source.selected)
      .subscribe(data =>
        {
          console.log(data);
          this.openSnackBar('Success!');
          //getusersofrefreshedpartners
          this.getusersofRefreshedPatners();
          
        },
        err => {
          console.log(err);
          this.openSnackBar(err);
        }
        );
    }
  }
  getusersofRefreshedPatners(){
    this.allUsers = [];
    this.selected.forEach(indorg => {
      this.userService.getUsersByOrg(indorg).subscribe(data => {
        // console.log(data);  
         data.forEach(inddata => {
           inddata.orgname = indorg["c_org_name"];
         });
         this.allUsers = this.allUsers.concat(data);
         
         })
       
     });
    
    
  }
  getEventOrg(){
    this.eventService.getEvent(this.id).subscribe(event => {
      console.log(event);
      this.eventObj = event;
      this.getOrgVendors();
    });
  }
  getOrgVendors() {
    console.log(this.eventObj['c_org_id']);
    this.organizationService.getChildOrganizations(this.eventObj['c_org_id'])
    .subscribe(orgVendors =>{
        console.log(orgVendors);
        this.orgVendors = orgVendors;
        if(this.eventObj['c_org_id'] == this.userOrg['id'] )
          this.orgVendors.push(this.userOrg);
      }
    );
  }
  getEventPartners(){
    this.eventService. getEventPartners(this.id)
    .subscribe(partners => {
      console.log(partners);
      partners.forEach(element => {
        this.selected.push(element.id);
      });
      this.getAssociatedUsersofEvent();
      //this.getAssociatedUsersofEvent();
      
    })
  }

 
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
