import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { B2cUserServiceService } from '../b2c-user-service.service'
import {
  Renderer2,
  ElementRef,
} from "@angular/core";
import { ModalService } from './ModalService';
@Component({
  selector: 'app-b2c-user',
  templateUrl: './b2c-user.component.html',
  styleUrls: ['./b2c-user.component.css']
})
export class B2cUserComponent implements OnInit {
  tagsObservable;
  TagsData: Array<any>;
  newUserDetails;
  userDetailsObservable;
  constructor( private b2cUserService: B2cUserServiceService,private targetEl: ElementRef, private renderer: Renderer2) {
    this.startApi()
   }
   private _myDirective: boolean;
   private _$matCard;
  ngOnInit() {
    this.TagsData = []
    //send to read /
    this.b2cUserService.eventCreated$
    .subscribe(data => {
       let tempTags ;
       tempTags = data.data;
       //console.log(tempTags.length)
       tempTags.forEach(tag => {
         //console.log(tag)
         this.addTag(tag)
       });
    });
    
  }
  addTag(tag){
    
     if(this.TagsData.indexOf(tag) > -1 ){

    }else{
      this.TagsData.push(tag)
      this.render(tag)
    }
    console.log(this.TagsData.length)
  }
  
  render(tag){
      //call api to get Data
      const grid = document.getElementById("grid")
      var html = "";
      var html1 = "";
      console.log(tag)
      
      var modal = document.getElementById("myModal");
      var popup = document.getElementById("popuptext");
      this.userDetailsObservable =  this.b2cUserService.getB2CUserDetails(tag)
      this.userDetailsObservable.subscribe(response => {
        if(response.status != false){
          console.log(grid.innerHTML)
          let source = "data:image/jpeg;base64"+","+response.data['c_b2c_image']
          html += "<div style='width:25%;float:left;text-align:center'>"
          html +="<h5 style='text-align:center;text-transform:uppercase'>"+response.data['c_b2c_uname']+"</h5>";
          html += '<img  src="'+source+'"">'
          html += "<div>"
          html1 += "<div>"
          html1 +="<h5>Hi <span style='color:red;text-transform:uppercase'>"+response.data['c_b2c_uname']+"</span> Welcome to the Annual Event!</h5>";
          html1 += '<img src="'+source+'">'
          html1 += "<div>"
          
          this.toggleVisibity(modal)
          
          //modal.style.display = "block"
          console.log(response)
         // setTimeout(function(){ console.log("wait over");/*modal.style.display = "none";*/ }, 3000);
        }
        grid.innerHTML += html
        popup.innerHTML = html1
  
        var span = document.getElementsByClassName("close")[0];

          

          // When the user clicks on <span> (x), close the modal
        

          // When the user clicks anywhere outside of the modal, close it
          window.onclick = function(event) {
            if (event.target == modal) {
              modal.style.display = "none";
            }
          }
        
      })
  }
  toggleVisibity(element){
    if (element.classList.contains('hidden')) {
      element.classList.remove('hidden');
      setTimeout(function () {
        element.classList.add('visuallyhidden');
        element.classList.remove('visuallyhidden')
          element.classList.add('hidden');
        
      }, 3000);
    } else {
      /*element.classList.add('visuallyhidden');    
      element.addEventListener('transitionend', function(e) {
        element.classList.add('hidden');
      }, {
        capture: false,
        once: true,
        passive: false
      });*/
    }
  
  }
  animateCSS(element, animationName, callback) {
    const node = document.querySelector(element)
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName)
        node.removeEventListener('animationend', handleAnimationEnd)

        if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
}
  startApi(){
      this.tagsObservable = this.b2cUserService.readTags();
      this.tagsObservable.toArray().subscribe(response => {
        console.log(response)
      });
  }

}
