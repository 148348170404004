import { Component, OnInit } from "@angular/core";
import { AuthService } from "./auth.service";
import { MatSnackBar } from "@angular/material";
import { UserService } from "./users/user.service";
import { OrganizationService } from "./organizations/organization.service";
import { LocService } from "./locs/loc.service";
import { EventService } from "./events/event.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "Checkmate Dashboard";
  successMessage: string;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private locService: LocService,
    private eventService: EventService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    // user has logged in
    this.authService.userLoggedIn$.subscribe(() => {
      this.successMessage = `Successfully logged in !`;
      this.clearMessages();
      this.openSnackBar(this.successMessage);
    });

    this.authService.userLoggedOut$.subscribe(() => {
      this.successMessage = `Successfully logged out !`;

      console.log(this.successMessage);
      this.clearMessages();
      this.openSnackBar(this.successMessage);
    });

    // user related alerts

    this.userService.userCreated$.subscribe(() => {
      this.successMessage = `User created Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.userService.userUpdated$.subscribe(() => {
      this.successMessage = `User updated Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.userService.userDeleted$.subscribe(() => {
      this.successMessage = `User deleted Successfully`;
      this.openSnackBar(this.successMessage);
    });

    // organization related alerts

    this.organizationService.organizationCreated$.subscribe(() => {
      this.successMessage = `Organization created Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.organizationService.organizationUpdated$.subscribe(() => {
      this.successMessage = `Organization updated Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.organizationService.organizationDeleted$.subscribe(() => {
      this.successMessage = `Organization deleted Successfully`;
      this.openSnackBar(this.successMessage);
    });

    // Location related alerts

    this.locService.locCreated$.subscribe(() => {
      this.successMessage = `Location created Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.locService.locUpdated$.subscribe(() => {
      this.successMessage = `Location updated Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.locService.locDeleted$.subscribe(() => {
      this.successMessage = `Location deleted Successfully`;
      this.openSnackBar(this.successMessage);
    });

    // Event related alerts

    this.eventService.eventCreated$.subscribe(() => {
      this.successMessage = `Event created Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.eventService.eventUpdated$.subscribe(() => {
      this.successMessage = `Event updated Successfully`;
      this.openSnackBar(this.successMessage);
    });

    this.eventService.eventDeleted$.subscribe(() => {
      this.successMessage = `Event deleted Successfully`;
      this.openSnackBar(this.successMessage);
    });

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }

  clearMessages() {
    setTimeout(() => {
      this.successMessage = "";
    }, 5000);
  }
}
