import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { CredentialForm } from './credentialForm';
import { ChangePasswordForm } from './change-password/changePasswordForm';

import 'rxjs/add/operator/mergeMap';
import { BURL } from './back-end-urls';

@Injectable()
export class AuthService {

  // IMP NOTICE: This service is making use of http module which is deprecated and rxjs5 which is not supported by Angular.
  // Please plan to migrate this to latest versions of the two.

  private loggedIn = false;

  // Observable Source
  private userLoggedInSource = new Subject();
  private userLoggedOutSource = new Subject();
  private userDetailsLoadedSource = new Subject();

  // Observable Stream
  userLoggedIn$ = this.userLoggedInSource.asObservable();
  userLoggedOut$ = this.userLoggedOutSource.asObservable();
  userDetailsLoaded$ = this.userDetailsLoadedSource.asObservable();

  constructor(private http: Http) {
    // check localstorage to see if the user is logged in.
    this.loggedIn = !!localStorage.getItem('access_token');
  }

  /**
   * Check if the user is logged in
   */
  isLoggedIn() {
    console.log(this.loggedIn);
    const promise = new Promise(
      (resolve, reject) => { resolve(this.loggedIn); }
    );
    return promise;
  }

  /**
   * Log the user in
   */
  login(credentialForm: CredentialForm): Observable<string> {
    const creds = {
      username: credentialForm.username,
      password: credentialForm.password,
    };

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${BURL.mainURLYii}/t-ops-user/login`, creds, {headers})
    .map(res => res.json())
    .do(res => {
      if (res.status === false) {
        console.log('this has an error : ' + res.message);
        throw new Error(res.message);
      } else {
        if (res.token) {
          localStorage.setItem('access_token', res.token);
          this.loggedIn = true;
          this.userLoggedIn();
          
        }
      }
    })
    .catch(this.handleError);
  }

  getAllUserRolesofUId(id: number) {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const opsuserrole = {
      'c_ops_uid': id
    };
    return this.http.post(`${BURL.mainURLYii}/t-ops-user/get-all-user-roles`, opsuserrole, { headers })
      .map(res => res.json())
      
    // .map(this.toEntity)
    .catch(this.handleError);
  }

  getOpsUserDetails() {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(`${BURL.mainURLYii}/t-ops-user/get-ops-user-details`, {headers})
    .map(res => res.json())
    .mergeMap((res) => {
      let data: any = res;
      localStorage.setItem('user', JSON.stringify(data));
      console.log(data);
      console.log(data.c_ops_org_id);
      if (data.c_ops_org_id) {
        return this.http.get(`${BURL.mainURLYii}/t-organizations/${data.c_ops_org_id}`, { headers });
      }
    })
    .map(res => res.json())
    .do(res => {
        localStorage.setItem('org', JSON.stringify(res));
        this.userDetailsLoaded();
      })
    .catch(this.handleError);

  }


  /**
   * Log the user out.
   */
  logout() {
    localStorage.removeItem('access_token');
    localStorage.clear();
    this.loggedIn = false;
    this.userLoggedOut();
    // commenting the call till 403 error is fixed
    // const headers = new Headers();
    // const token = localStorage.getItem('access_token');
    // headers.append('Content-Type', 'application/json');
    // headers.append('Authorization', `Bearer ${token}`);
    // const fcmtokenParams = {
    //   "fcm_token": localStorage.getItem('fcmtoken')
    // };
    // return this.http.post(`${BURL.mainURLYii}/site/logout`, fcmtokenParams, { headers })
    //   .map(res => res.json())
    //   .do(res => {
    //     if (res.status !== true) {
    //       console.log('this has an error : ' + res.message);
    //       throw new Error(res.message);
    //     } else {
    //       localStorage.removeItem('access_token');
    //       localStorage.clear();
    //       this.loggedIn = false;
    //       this.userLoggedOut();
    //     }
    //   })
    //   .catch(this.handleError);
  }

  // State Update functions.
  userLoggedIn() {
    this.userLoggedInSource.next();
  }

  userLoggedOut() {
    this.userLoggedOutSource.next();
  }

  userDetailsLoaded() {
    this.userDetailsLoadedSource.next();
  }

  /**
   * Resets password when the user has forgotten the password.
   * @param email
   */
  // forgotPassword(email): Observable<string> {
  //   let creds = {
  //     customer_username : email
  //   };
  //   const headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.post(`${BURL.mainURLYii}/others/forgotpwd`, creds, {headers})
  //   .map(res => res.json())
  //   .catch(this.handleError);
  // }
  // TBD not yet implemented in API

  /**
   * Changes password of logged in user.
   * @param changePasswordForm
   */
  changePassword(changePasswordForm: ChangePasswordForm): Observable<string> {
    let creds = {
      old_password: changePasswordForm.old_password,
      new_password: changePasswordForm.new_password,
      confirm_password: changePasswordForm.confirm_password
    };
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-ops-user/update-password`, creds, {headers})
    .map(res => res.json())
    .catch(this.handleError);
  }



  /**
   * Google sign in
   * @param token
   */
  sendToRestApiMethod(gAuth: string): Observable<string> {
    const payload = {
      AccessToken: gAuth,
      custom_origin: 'commercial'
    };
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/oauth/google`, payload, {headers})
    .mergeMap((res) => {
      const data: any = res.json();
      // console.log(data);
      // console.log(data.status);
      if (data.status === false) {
        console.log('this has an error : ' + data.message);
        throw new Error(data.message);
      } else {

        if (data.access_token) {
          console.log(data);
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('user_id', data.customer_id);
          this.loggedIn = true;
          this.userLoggedIn();
          const id = data.customer_id;
          return this.http.get(`${BURL.mainURLExpress}/users/get-org-map/${id}`);
        }
      }
    })
      .map(res => res.json())
      .catch(this.handleError);
 }

  revokeRoleFromUser(data: {role: string, user: number}): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-ops-user/revoke-role-from-user`, data, { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }

  grantRoleToUser(data: {role: string, user: number}) {

    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-ops-user/grant-role-to-user`, data, { headers })
      .map(res => res.json())
      // .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }

  getAllSystemRoles(): Observable<any> {

    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(`${BURL.mainURLYii}/t-ops-user/get-all-system-roles`, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  getAllSystemPermissions(): Observable<any> {

    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(`${BURL.mainURLYii}/t-ops-user/get-all-system-permissions`, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  getAllUserRoles(): Observable<any> {

    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(`${BURL.mainURLYii}/t-ops-user/get-all-user-roles`, { headers })
      .map(res => res.json())
      .do(res => {
        localStorage.setItem('roles', JSON.stringify(res));
      })
      .catch(this.handleError);
  }

  listAllPermissions(): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.get(`${BURL.mainURLYii}/t-ops-user/list-all-permissions`, { headers })
      .map(res => res.json())
      .do(res => {
        console.log(res);
        localStorage.setItem('permissions', JSON.stringify(res));
      })
      .catch(this.handleError);
  }
  listAllPermissionsbyUid(user_id): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    const data = {
      'c_ops_uid': user_id
    }
    return this.http.post(`${BURL.mainURLYii}/t-ops-user/list-all-permissions`, data, { headers })
      .map(res => res.json())
      .do(res => {
        console.log(res);
      })
      .catch(this.handleError);
  }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
