import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Router, Params, ActivatedRoute } from "@angular/router";
import { UserService } from "../user.service";
import { User } from "../user";
import { Location } from "@angular/common";
import { MatTableDataSource } from "@angular/material";
import { APPCONSTANTS } from '../../app-constants';
@Component({
  selector: "app-user-view",
  templateUrl: "./user-view.component.html",
  styleUrls: ["./user-view.component.css"]
})
export class UserViewComponent implements OnInit {
  userForm: FormGroup;
  appConstants;
  permissions;
  image =  null;
  // for form labels
  hro = this.userService.human_readable_obj;

  user: User;
  showSpinner = true;
  displayedColumns = ["name", "details"];
  ELEMENT_DATA = [];
  id;
  dataSource = new MatTableDataSource<Element>();

  constructor(
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params["id"];
    this.appConstants = APPCONSTANTS;
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.userService.getUser(this.id).subscribe(user => {
      this.user = user;
      console.log(user);
      // let user_keys = user.keys();
      for (let i in user) {
        //console.log(i);
        if(["c_password", "c_access_token", "c_exp_date"].includes(i)){continue;}
        let key = this.userService.human_readable_obj[i];
        if(i == "c_user_image"){
          if( user[i] != ""){
            this.image = "data:image/jpeg;base64"+","+user[i];
            console.log(this.image)
          }
          
        }else{
          this.ELEMENT_DATA.push({ name: key, details: user[i] });
        }
        
      }

      console.log("Ele" + JSON.stringify(this.ELEMENT_DATA));
      this.dataSource.data = this.ELEMENT_DATA;
      this.showSpinner = false;
    });
  }


  goBack() {
    this._location.back();
  }
}
