import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../../auth.service';
import { BURL } from '../../back-end-urls';
import { AccessPoint } from './access-point';
import { AccessPointGroup } from './access-point-group';


@Injectable({
  providedIn: 'root'
})
export class AccessPointService {

  constructor(
    private http: Http,
    private authService: AuthService
  ) { }

    // Observable Source
    private apCreatedSource = new Subject<AccessPoint>();
    private apUpdatedSource = new Subject<AccessPoint>();
    private apDeletedSource = new Subject();

    private apgCreatedSource = new Subject<AccessPointGroup>();
    private apgUpdatedSource = new Subject<AccessPointGroup>();
    private apgDeletedSource = new Subject();
    // Observable Stream
    apCreated$ = this.apCreatedSource.asObservable();
    apUpdated$ = this.apUpdatedSource.asObservable();
    apDeleted$ = this.apDeletedSource.asObservable();

    apgCreated$ = this.apgCreatedSource.asObservable();
    apgUpdated$ = this.apgUpdatedSource.asObservable();
    apgDeleted$ = this.apgDeletedSource.asObservable();


  getAccessPoints(id: string, type: string): Observable<AccessPoint[]> {
    const data = {
      'c_location_id': id,
      'c_type': type
    };
    console.log(BURL.mainURLYii);
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-location/get-access-points`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  /**
   * get a single access point
   */
  getAccessPoint(id: string): Observable<AccessPoint> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`${BURL.mainURLYii}/t-access-points/${id}`, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  getAccessPointGroups(id: string): Observable<AccessPointGroup[]> {
    const data = {
      'c_location_id' : id
    };
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-location/get-access-point-groups`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  /**
   * get a single access point group
   */
  getAccessPointGroup(id: string): Observable<AccessPointGroup> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`${BURL.mainURLYii}/t-access-point-groups/${id}`, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  /**
   * create an access point
   * @param apg
   */
  createAccessPoint(apg: AccessPoint, c_type: string): Observable<AccessPoint> {
    apg.c_org_id = JSON.parse(localStorage.getItem('loc_obj'))['c_org_id'];
    apg.c_type = c_type;
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-access-points`, apg, { headers })
      .map(res => res.json())
      .do(apg => this.apCreated(apg))
      .catch(this.handleError);
  }

  /**
   * create an access point group
   * @param apg
   */
  createAccessPointGroup(apg: AccessPointGroup): Observable<AccessPointGroup> {
    // apg.c_org_id = 1; // This is temporary. this will be changed once org contex is available
    apg.c_org_id = JSON.parse(localStorage.getItem('loc_obj'))['c_org_id'];
    apg.c_location_id = JSON.parse(localStorage.getItem('loc_obj'))['id'];
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-access-point-groups`, apg, { headers })
      .map(res => res.json())
      .do(apg => this.apgCreated(apg))
      .catch(this.handleError);
  }


  /**
   * delete an access point
   * @param id
   */
  deleteAccessPoint(id: string): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.delete(`${BURL.mainURLYii}/t-access-points/${id}`, { headers })
      .map(res => res.json())
      .do(res => this.apDeleted())
      .catch(this.handleError);
  }

  /**
   * delete an access point group
   * @param id
   */
  deleteAccessPointGroup(id: string): Observable<any> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.delete(`${BURL.mainURLYii}/t-access-point-groups/${id}`, { headers })
      .map(res => res.json())
      .do(res => this.apgDeleted())
      .catch(this.handleError);
  }

  /**
   * update an access point
   * @param ap
   * @param id
   */
  updateAccessPoint(ap: AccessPoint, id: string): Observable<AccessPoint> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.put(`${BURL.mainURLYii}/t-access-points/${id}`, ap, { headers })
      .map(res => res.json())
      .do(res => this.apUpdated(ap))
      .catch(this.handleError);
  }

  /**
   * update an access point group
   * @param apg
   * @param id
   */
  updateAccessPointGroup(apg: AccessPointGroup, id: string): Observable<AccessPointGroup> {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.put(`${BURL.mainURLYii}/t-access-point-groups/${id}`, apg, { headers })
      .map(res => res.json())
      .do(res => this.apgUpdated(apg))
      .catch(this.handleError);
  }

  apUpdated(ap: AccessPoint) {
    this.apUpdatedSource.next(ap);
  }

  apCreated(ap: AccessPoint) {
    console.log('apCreated reached');
    this.apCreatedSource.next(ap);
  }

  apDeleted() {
    this.apDeletedSource.next();
  }

  apgUpdated(apg: AccessPointGroup) {
    this.apgUpdatedSource.next(apg);
  }

  apgCreated(apg: AccessPointGroup) {
    this.apgCreatedSource.next(apg);
  }

  apgDeleted() {
    this.apgDeletedSource.next();
  }

  /**
   * allocate access points to a access point group
   * @param apgId number
   * @param apIds number[]
   */
  allocateAccessPoints(data: {c_apg_id: number, c_ap: number[]}): Observable<any>  {
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-access-point-group/allocate-access-points`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }
  allocateorDeallocateAccessPoints(apid, allordealloc, apgid)  {
    const data = {
      c_apg_id: apgid,
      c_ap:[apid]
    }
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    if(allordealloc == true){
      return this.http.post(`${BURL.mainURLYii}/t-access-point-group/allocate-access-points`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
    }else{
      return this.http.post(`${BURL.mainURLYii}/t-access-point-group/de-allocate-access-points`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
    }
    
  }
  /**
   * get access points for an access point group
   * @param apgId number
   */
  getAccessPointsWithApgId(apgId: number): Observable<any> {
    const data = {
      c_apg_id: apgId
    };
    const headers = new Headers();
    const token = localStorage.getItem('access_token');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(`${BURL.mainURLYii}/t-access-point-group/get-access-points-with-apg-id`, data, { headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  /**
   * Handle any errors from the API
   */
  private handleError(err) {
    let errMessage: string;

    if (err instanceof Response) {
      const body = err.json() || '';
      const error = body.message || JSON.stringify(body);
      errMessage = `${err.status} - ${err.statusText || ''}:  ${error}`;
    } else {
      errMessage = err.message ? err.message : err.toString();
    }

    return Observable.throw(errMessage);
  }
}
