import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Loc } from 'src/app/locs/loc';
import { UserService } from '../../user.service';
import { LocService } from 'src/app/locs/loc.service';
import { MatSnackBar } from "@angular/material";
import {AssignUsersService } from 'src/app/locs/loc-view/assign-users/assign-users.service'

@Component({
  selector: 'app-allocate-location',
  templateUrl: './allocate-location.component.html',
  styleUrls: ['./allocate-location.component.css']
})
export class AllocateLocationComponent implements OnInit {
  allocLocOpsUserForm: FormGroup;
  locs = [];
  allocatedLocs: Loc[];
  selected =[];
  userOrg;
  id: string;
  toppings = new FormControl();
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  retrieveDataResolver;
  constructor(
    private userService: UserService,
    private locService: LocService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private assignUsers: AssignUsersService

  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.locs = [{"id":1,"c_name":"jaydeep"},{"id":2,"c_name":"swathi"}]
      //this.selected.push(1);
    this.getSelectedBeforegetAll();
    
    // this.getAllocatedLocs();
    this.allocLocOpsUserForm = this.fb.group({
      c_location_id: [null],
      c_ops_uid: [this.id]
    });
  }
  getSelectedBeforegetAll() {
    this.getAllocatedLocs().then(() => {
      this.getLocs();
      
    });
  }
  getLocs() {
    this.locService.getLocs(this.userOrg['id'])
      .subscribe(locs => {
        this.locs = locs;
        console.log(this.locs)
      },
      err => {
        console.log(err);
      });
     
  }

  allocLocOpsUser() {
    this.userService.allocateLocations(this.allocLocOpsUserForm.value)
    .subscribe(
      data => {
        console.log(data);
        this.openSnackBar(data.message);
        // this.allocLocOpsUserForm.reset();
        // this.getAllocatedLocs();
      },
      err => {
        console.log(err);
        this.openSnackBar(err);
      }
    );
  }
  change(event)
  {
    if(event.isUserInput) {
      console.log(event.source.value, event.source.selected);
      this.assignUsers.allocateordeallocateUsertoLocation(event.source.value,this.id, event.source.selected).subscribe(data=>{
        console.log(data);
        this.openSnackBar(data.message)
      })
    }
  }
   getAllocatedLocs():Promise<any> {
    return new Promise(resolve => {
     this.userService.getAllocatedLocations(this.id)
     .subscribe(allocatedLocs => {

       this.allocatedLocs = allocatedLocs;

       this.allocatedLocs.forEach(elem=> {
         this.selected.push(elem.id);
               })
        console.log(this.selected)
        
     },
     err => {
       console.log(err);
       this.openSnackBar(err);
     })
     
     this.retrieveDataResolver = resolve;
        this.retrieveData();
    })
     
   }
   retrieveData(): void {
    // your async retrieval data logic goes here
    console.log('1. GETTING DATA FROM SERVER');
    setTimeout(() => {
      // <--- Change it - your service data retrieval
      //this.dataStorage = '++DATA++';
      this.retrieveDataResolver(); // <--- This must be called as soon as the data are ready to be displayed
    }, 1000);
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
