import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { OrganizationService } from '../organization.service';
import { Organization } from '../organization';
import { Location } from '@angular/common';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { APPCONSTANTS } from '../../app-constants';
@Component({
  selector: 'app-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.css']
})
export class OrganizationCreateComponent implements OnInit {
  roles :any =  '';
  organizationForm: FormGroup;
  croppedImage : any = '';
  imageChangedEvent: any = '';
  selectedFile: Blob;
  appConstants; any = '';
  // for form labels
  userOrg;
  hro = this.organizationService.human_readable_obj;
  organizations = [];
  c_org_type_enum_values = [
    {value: 'MASTER'},
    {value: 'PARTNER'},
    {value: 'VENDOR'},
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private _location: Location,
    private fb: FormBuilder,

  ) { }

  ngOnInit() {
    this.appConstants = APPCONSTANTS;
    console.log(this.appConstants);
    this.roles = JSON.parse(localStorage.getItem('roles'));
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.getChildOrganizations(this.userOrg['id']);
   
      
    this.organizationForm = this.fb.group({
      c_org_name : [null, Validators.required] ,
      c_org_domain : [null] ,
      c_org_type : [null, Validators.required] ,
      c_parent_org_id : [null] ,
      c_address : [null],
      c_city : [null],
      c_state : [null],
      c_pin : [null],
      c_country : [null],
      c_latlong : [null],
      c_org_img: [null],
      orgimage: [null]
    });
  }

  filechange(event) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    var file:File =event.target.files[0];
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.croppedImage = myReader.result;
    }
    myReader.readAsDataURL(file);
    console.log(this.croppedImage)
  }

  

  createOrganization() {
    this.organizationForm.patchValue({ c_org_img : this.croppedImage.split(",")[1] });
    this.organizationService.createOrganization(this.organizationForm.value)
    
      .subscribe(organization => {
        // this.successMessage = 'Organization was created!';
        console.log('organization was created');

        // navigate back to the index page
        this.router.navigate(['/organizations']);
      },
      err => {
        // this.errorMessage = err;
        console.log(err);
      }
      );

  }
  getChildOrganizations(orgid) {
    this.organizationService.getChildOrganizations(orgid)
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
        this.organizations.push(this.userOrg);
      });
  }

  goBack() {
    this._location.back();
  }

}
