import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { LocService } from '../loc.service';
import { Loc } from '../loc';
import { Location } from '@angular/common';
import { OrganizationService } from '../../organizations/organization.service';
import {Organization} from '../../organizations/organization';
import { MatSnackBar } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-loc-create',
  templateUrl: './loc-create.component.html',
  styleUrls: ['./loc-create.component.css']
})
export class LocCreateComponent implements OnInit {

  locForm: FormGroup;
  croppedImage : any = '';
  imageChangedEvent: any = '';
  selectedFile: Blob;
  // for form labels
  hro = this.locService.human_readable_obj;
  userOrg;
  c_type_enum_values = [
    {value: 'AUDITORIUM'},
    {value: 'COMPLEX'},
    {value: 'SPORTS CLUB'},
    {value: 'OFFICE'},
    {value: 'HOME'},
    {value: 'BUILDING'},
    {value: 'APARTMENT'},
    {value: 'VILLA'},
    {value: 'HOSTEL'},
    {value: 'GROUND'},
    {value: 'OTHER'}
  ];

  organizations: Organization[];
  parentLocs: Loc[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locService: LocService,
    private _location: Location,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.userOrg = JSON.parse(localStorage.getItem('org'));
    this.getChildOrganizations(this.userOrg['id']);
    this.getLocs(this.userOrg['id']);
    this.locForm = this.fb.group({
      c_org_id : [null,Validators.required],
      c_parent_id : [null],
      c_name : [null, Validators.required],
      c_type : [null, Validators.required],
      c_addess_id : [null],
      c_img : [null],
      locimg :[null],
    });
  }
  filechange(event) {
    this.imageChangedEvent = event;
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    var file:File =event.target.files[0];
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      this.croppedImage = myReader.result;
      console.log(this.croppedImage)
      this.croppedImage = myReader.result;
    }
    myReader.readAsDataURL(file);
  }
 


  createLoc() {
    this.locForm.patchValue({ c_img : this.croppedImage.split(",")[1] });
    this.locService.createLoc(this.locForm.value)
      .subscribe(loc => {
        // this.successMessage = 'Loc was created!';
        console.log('loc was created');

        // navigate back to the index page
        this.router.navigate(['/locs']);
      },
      err => {
        // this.errorMessage = err;
        console.log(err);
        this.openSnackBar(err);
      }
      );

  }

  getChildOrganizations(orgid) {

    this.organizationService.getChildOrganizations(orgid)
    
      .subscribe(orgs => {
        console.log(orgs);
        this.organizations = orgs;
        this.organizations.push(this.userOrg);
      });
  }
  organizationChange(event){
    //console.log(event);
    this.getLocs(event.value);
  }
  getLocs(orgid) {
    this.locService.getLocs(orgid)
      .subscribe(locs => {
        this.parentLocs = locs;
      });
  }

  goBack() {
    this._location.back();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
