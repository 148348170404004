import { Component, OnInit, ViewChild, AfterViewInit  } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { Router } from '@angular/router';
import { Organization } from '../organization';
import { APPCONSTANTS } from '../../app-constants';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import 'rxjs/add/operator/toArray';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css']
})
export class OrganizationListComponent implements OnInit, AfterViewInit {
  isLoadingResults = true;
  isRateLimitReached;
  permissions;
  appconstants;
  // for form labels
  hro = this.organizationService.human_readable_obj;
  displayedColumns = [
    'c_org_name' ,
    'c_org_domain' ,
    'c_org_type' ,
    'c_parent_org_id' ,
    'c_city' ,
    'c_date_created' ,
    'actions'
  ];
  userOrg;
  selectedOrg;
  organizationObservable;
  dataSource = new MatTableDataSource<Organization>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    public snackBar: MatSnackBar,
   ) {
     this.appconstants = APPCONSTANTS;
     this.userOrg = JSON.parse(localStorage.getItem('org'));
     this.permissions = JSON.parse(localStorage.getItem('permissions'));
     console.log(this.permissions.indexOf(this.appconstants.getAllRootOrgs));
     if(this.permissions.indexOf(this.appconstants.getAllRootOrgs) !== -1){
      this.getAllRootOrgs();
     }else{
      this.getChildOrganizations(this.userOrg['id']);
     }
     
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
    console.log(this.paginator);
  }

  getChildOrganizations(orgid) {
    this.organizationObservable = this.organizationService.getChildOrganizations(orgid);
    this.organizationObservable.toArray()
      .subscribe(arr => {
        console.log(arr);
        this.dataSource.data = arr[0];
        this.isLoadingResults = false;
      });
  }
  getAllRootOrgs() {
    this.organizationObservable = this.organizationService.getAllRootOrganizations();
    this.organizationObservable.toArray()
      .subscribe(arr => {
        console.log(arr);
        this.dataSource.data = arr[0];
        this.isLoadingResults = false;
      });
  }
  confirmDelete( id: string) {
    if (confirm(' Deleting ' + id + '. This action is irreversible. Are you sure? ')) {
      console.log('confirmed by user');
      this.isLoadingResults = true;
      this.organizationService.deleteOrganization(id)
        .subscribe(() => {
          if(this.permissions.indexOf(this.appconstants.getAllRootOrgs) !== -1){
            this.getAllRootOrgs();
           }else{
            this.getChildOrganizations(this.userOrg['id']);
           }
        },
        err => {
          if(this.permissions.indexOf(this.appconstants.getAllRootOrgs) !== -1){
            this.getAllRootOrgs();
           }else{
            this.getChildOrganizations(this.userOrg['id']);
           }
          this.openSnackBar(err);
        });
    } else {
      console.log('cancelled by user');
      this.openSnackBar('Cancelled by user!');
    }

  }

  getCreateOrganizationForm() {
    this.router.navigate(['organizations/create']);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 5000
    });
  }
}
